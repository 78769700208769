.wrapper.login {
  background-color: #f5f5f5;
  .login {
    padding: convertToVw(50px) 0 convertToVw(30px);
    @include mq_min_750 {
      padding: 50px 0 30px;
    }
    .login-logo {
      max-width: convertToVw(300px);
      max-height: convertToVw(300px);
      margin: 0 auto;
      @include mq_min_750 {
        max-width: 300px;
        max-height: 300px;
      }
    }
    .login-title {
      text-align: center;
      @include set_font(18px, 300, 1);
      margin: convertToVw(30px) 0;
      color: #1a75bb;
      @include mq_min_750 {
        margin: 30px 0;
        @include set_font(30px, 300, 1);
      }
    }
    .login-form {
      margin-top: convertToVw(50px);
      @include mq_min_750 {
        margin-top: 50px;
      }
      .login-form-main {
        .login-form-group {
          position: relative;
          &:nth-child(2) {
            margin-top: convertToVw(45px);
            @include mq_min_750 {
              margin-top: 45px;
            }
          }
          input {
            // min-height: convertToVw(81px);
            padding: convertToVw(15px) convertToVw(15px) convertToVw(15px) convertToVw(70px);
            border: 1px solid #7f7f7f;
            border-radius: 4px;
            @include mq_min_750 {
              padding: 15px 15px 15px 70px;
            }
            &:-webkit-autofill,
            &:-webkit-autofill:hover,
            &:-webkit-autofill:focus,
            &:-webkit-autofill:active {
              transition: background-color 5000000s ease-in-out 0s;
            }
            &::-ms-reveal,
            &::-ms-clear {
              display: none;
            }
          }
          .icon {
            max-width: convertToVw(35px);
            max-height: convertToVw(35px);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: convertToVw(15px);
            @include mq_min_750 {
              max-width: 35px;
              max-height: 35px;
              left: 15px;
            }
            img {
              object-fit: contain;
              aspect-ratio: 1;
            }
          }
          .eye-icon {
            cursor: pointer;
            max-width: convertToVw(35px);
            max-height: convertToVw(35px);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: convertToVw(15px);
            @include mq_min_750 {
              max-width: 35px;
              max-height: 35px;
              right: 15px;
            }
            img {
              object-fit: contain;
              aspect-ratio: 1;
            }
          }
        }
      }
      .login-form-button {
        @include flex(space-between, normal);
        margin-top: convertToVw(45px);
        @include mq_min_750 {
          margin-top: 45px;
        }
        .auth-button {
          width: calc(100% / 2 - convertToVw(20px));
          padding: convertToVw(15px) convertToVw(60px);
          text-align: center;
          //   @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            width: calc(100% / 2 - 20px);
            padding: 15px 60px;
            // @include set_font(25px, 400, 1);
          }
        }
        // .button-register {
        // 	border: 1px solid #4260a2;
        // 	border-radius: 4px;
        // 	color: #4260a2;
        // }
        // .button-login {
        // 	border: 1px solid transparent;
        // 	border-radius: 4px;
        // 	background: linear-gradient(to right, #4f5bb4, #359dd4)
        // 		border-box;
        // 	background-repeat: no-repeat;
        // 	color: #fff;
        // }
      }
    }
    .login-social {
      margin-top: convertToVw(100px);
      @include mq_min_750 {
        margin-top: 70px;
      }
      .login-social-button {
        // height: convertToVw(50px);
        min-width: convertToVw(480px);
        @include flex(center, center);
        margin: 0 auto convertToVw(40px);
        padding: convertToVw(30px) 0;
        border-radius: 4px;
        // @include set_font(1em, 500, 1);
        background-color: #fff;
        // color: #4260a2;
        @include mq_min_750 {
          min-width: 550px;
          margin: 0 auto 30px;
          padding: 15px 0;
          //   height: 50px;
          //   font-size: 20px;
          //   font-weight: 600;
        }
        .login-social-icon {
          width: convertToVw(30px);
          height: convertToVw(30px);
          margin-right: convertToVw(15px);
          @include mq_min_750 {
            width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          img {
            object-fit: contain;
            aspect-ratio: 1;
          }
        }
      }
      .apple-auth-btn {
        // height: convertToVw(50px);
        min-width: convertToVw(480px);
        display: flex;
        margin: 0 auto;
        border: 0;
        @include mq_min_750 {
          min-width: 550px;
          //   height: 50px;
          //   font-size: 20px;
          //   font-weight: 600;
        }
        svg {
          //   width: convertToVw(20px);
          height: convertToVw(30px);
          margin-right: convertToVw(15px);
          @include mq_min_750 {
            // width: 20px;
            height: 20px;
            margin-right: 5px;
          }
          rect {
            display: none;
          }
          path {
            transform: scale3d(3, 3, 3) translate(-8px, -14px);
          }
        }
      }
    }
    .forgot-password {
      @include flex(center, center);
      margin-top: convertToVw(100px);
      @include mq_min_750 {
        margin-top: 100px;
      }
      .forgot-password-button {
        // margin: 0 auto;
        padding: convertToVw(25px) convertToVw(60px);
        // @include set_font(convertToVw(25px), 500, 1);
        color: #6d6c6c;
        @include mq_min_750 {
          padding: 25px 60px;
          //   @include set_font(25px, 500, 1);
        }
      }
    }
    .term-of-use {
      display: block;
      margin-top: convertToVw(130px);
      padding: convertToVw(15px);
      text-align: center;
      color: #6d6c6c;
      //   @include set_font(convertToVw(25px), 400, 1);
      @include mq_min_750 {
        margin-top: 130px;
        padding: 15px;
        // @include set_font(25px, 400, 1);
      }
    }
  }
}
