.wrapper.transaction-statistic-partner {
  background-color: #d7dbe2;
  .transaction-statistic {
    padding-top: 30px;
    .transaction-statistic-title {
      padding: convertToVw(15px) convertToVw(30px);
      border-bottom: 1px solid #efefef;
      text-transform: uppercase;
      font-weight: 300;
      background-color: #fff;
      @include mq_min_750 {
        padding: 15px 30px;
      }
    }
    .transaction-statistic-item {
      @include flex(flex-start, center);
      margin-bottom: convertToVw(20px);
      padding: convertToVw(30px) convertToVw(30px) 0;
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 30px 30px 0;
      }
      .transaction-statistic-info {
        width: 50%;
        padding: convertToVw(30px) 0;
        text-align: center;
        @include mq_min_750 {
          padding: 30px 0;
        }
        &:first-child {
          border-right: 1px solid #efefef;
        }
        .transaction-statistic-text {
          margin-bottom: convertToVw(15px);
          //   @include set_font(convertToVw(40px), 400, 1);
          text-transform: uppercase;
          @include mq_min_750 {
            margin-bottom: 15px;
          }
        }
        .transaction-statistic-number {
          font-weight: 300;
        }
      }
    }
  }
}
