.wrapper.service-confirmation {
  background-color: #d7dbe2;
  .service-confirmation {
    padding-top: 30px;
    .service-confirmation-info {
      .booking-info {
        margin: convertToVw(20px) 0;
        padding: convertToVw(25px) convertToVw(30px);
        background-color: #fff;
        @include mq_min_750 {
          margin: 20px 0;
          padding: 25px 30px;
        }
        .booking-info-title {
          margin-bottom: convertToVw(20px);
          font-weight: 300;
          color: #1b75ba;
          @include mq_min_750 {
            margin-bottom: 20px;
          }
        }
        .booking-info-detail {
          width: 100%;
          .booking-info-key,
          .booking-info-value {
            padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
            @include mq_min_750 {
              padding: 15px 10px 15px 0;
            }
          }
        }
      }
      .user-info {
        margin-bottom: convertToVw(20px);
        padding: convertToVw(25px) convertToVw(30px);
        background-color: #fff;
        @include mq_min_750 {
          margin-bottom: 20px;
          padding: 25px 30px;
        }

        .user-info-name,
        .user-info-address {
          span {
            display: block;
          }
          .title {
            margin-bottom: convertToVw(15px);
            font-weight: 300;
            @include mq_min_750 {
              margin-bottom: 15px;
            }
          }
        }
        .user-info-name {
          margin-bottom: convertToVw(30px);
          @include mq_min_750 {
            margin-bottom: 30px;
          }
        }
      }
      .payment-info {
        margin-bottom: convertToVw(40px);
        padding: convertToVw(25px) convertToVw(30px);
        background-color: #fff;
        @include mq_min_750 {
          margin-bottom: 40px;
          padding: 25px 30px;
        }
        .payment-info-detail {
          width: 100%;
          .payment-info-key,
          .payment-info-value {
            padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
            @include mq_min_750 {
              padding: 15px 10px 15px 0;
            }
          }
          .payment-info-key {
            max-width: 50%;
            font-weight: 300;
          }
          .price {
            font-weight: 300;
            color: #da1f26;
          }
        }
      }
      .service-confirmation-button {
        padding: convertToVw(20px);
        @include mq_min_750 {
          padding: 20px;
        }
        .booking-button {
          width: 100%;
          // @include set_font(convertToVw(30px), 400, 1);
          // @include mq_min_750 {
          //   @include set_font(30px, 400, 1);
          // }
        }
      }
    }
  }
}
