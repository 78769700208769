.wrapper.notification {
  .notification-list {
    background-color: #d7dbe2;
    .notification-item {
      cursor: pointer;
      @include flex(flex-start, flex-start);
      margin-bottom: convertToVw(20px);
      padding: convertToVw(30px);
      border-radius: 10px;
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 30px;
      }
      &.read {
        color: #717377;
        .notification-item-time {
          color: #717377 !important;
        }
      }
      .notification-item-icon {
        width: convertToVw(50px);
        height: convertToVw(50px);
        margin-right: convertToVw(20px);
        @include mq_min_750 {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
        img {
          object-fit: contain;
        }
      }
      .notification-item-detail {
        width: calc(100% - convertToVw(70px));
        @include mq_min_750 {
          width: calc(100% - 70px);
        }
        .notification-item-title {
          margin-bottom: convertToVw(15px);
          font-weight: 300;
          line-height: 1.2;
          @include truncate_text(1);
          @include mq_min_750 {
            margin-bottom: 15px;
          }
        }
        .notification-item-description {
          margin-bottom: convertToVw(25px);
          line-height: 1.2;
          @include truncate_text(3);
          @include mq_min_750 {
            margin-bottom: 25px;
          }
          .amount {
            display: block;
          }
        }
        .notification-item-time {
          //   @include set_font(convertToVw(25px), 400, 1);
          color: #1a75bb;
          @include mq_min_750 {
            // @include set_font(25px, 400, 1);
          }
        }
      }
    }
  }
}
