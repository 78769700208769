.wrapper.task {
  .task {
    min-height: 100vh;
    .task-navbar {
      @include flex(flex-start, center);
      border-bottom: 1px solid #59657c;
      .navbar-item {
        width: calc(100% / 4);
        margin: convertToVw(10px) 0;
        padding: convertToVw(15px) convertToVw(10px);
        // @include set_font(convertToVw(30px), 400, 1);
        font-weight: 200;
        text-align: center;
        cursor: pointer;
        @include mq_min_750 {
          margin: 10px 0;
          padding: 15px 10px;
          //   @include set_font(30px, 400, 1);
        }

        border-right: 1px solid #59657c;
        &:last-child {
          border-right: 0;
        }
        &.active {
          color: #1a75bb;
        }
      }
    }
    .task-list {
      //   margin-top: convertToVw(20px);
      padding-bottom: convertToVw(50px);
      @include mq_min_750 {
        // margin-top: 20px;
        padding-bottom: 50px;
        // padding: 50px 30px;
      }
      .task-item {
        @include flex(flex-start, flex-start);
        // padding: convertToVw(20px) 0;
        padding: convertToVw(25px) convertToVw(30px);
        border-bottom: 1px solid #747e91;
        @include mq_min_750 {
          // padding: 20px 0;
          padding: 25px 30px;
        }
        .task-icon {
          width: convertToVw(50px);
          height: convertToVw(50px);
          margin-right: convertToVw(20px);
          filter: brightness(0) saturate(100%) invert(44%) sepia(98%) saturate(502%) hue-rotate(315deg) brightness(98%)
            contrast(92%);
          @include mq_min_750 {
            width: 50px;
            height: 50px;
            margin-right: 20px;
          }
          img {
            object-fit: contain;
          }
        }
        .task-info {
          width: calc(100% - convertToVw(70px));
          @include mq_min_750 {
            width: calc(100% - 70px);
          }
          .task-info-name {
            margin-bottom: convertToVw(15px);
            // @include set_font(convertToVw(25px), 600, 1);
            font-weight: 300;
            color: #1a75bb;
            @include mq_min_750 {
              margin-bottom: 15px;
              //   @include set_font(25px, 600, 1);
            }
          }
          .task-info-detail {
            width: 100%;
            margin-bottom: convertToVw(50px);
            @include mq_min_750 {
              margin-bottom: 50px;
            }
            .task-info-detail-item {
              //   @include set_font(convertToVw(35px), 400, 1.4);
              //   @include mq_min_750 {
              //     @include set_font(35px, 400, 1.4);
              //   }
              .task-info-detail-key,
              .task-info-detail-value {
                padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
                @include set_font(convertToVw(25px), 400, 1.4);
                vertical-align: top;
                @include mq_min_750 {
                  padding: 15px 10px 15px 0;
                  @include set_font(23px, 400, 1.4);
                }
              }
              .task-info-detail-key {
                min-width: 40%;
                white-space: nowrap;
              }
              .task-info-detail-value {
                min-width: 60%;
                // max-width: calc(60% - convertToVw(10px));
                padding-left: convertToVw(15px);
                @include mq_min_750 {
                  //   min-width: calc(60% - 10px);
                  //   max-width: calc(60% - 10px);
                  padding-left: 15px;
                }
                &.price {
                  font-weight: 300;
                  color: #d42727;
                }
              }
            }
          }
          .task-info-more {
            padding: convertToVw(15px) convertToVw(50px);
            border: 1px solid #1a75bb;
            border-radius: 30px;
            @include set_font(convertToVw(25px), 300, 1);
            color: #1a75bb;
            @include mq_min_750 {
              padding: 15px 50px;
              @include set_font(23px, 300, 1);
            }
          }
        }
      }
    }
  }
}
