.wrapper.register-speciality {
  .register-speciality {
    background-color: #d7dbe2;
    .register-speciality-title {
      margin-bottom: convertToVw(50px);
      text-align: center;
      font-weight: 300;
      //   @include set_font(convertToVw(25px), 600, 1);
      color: #1b75ba;
      @include mq_min_750 {
        margin-bottom: 50px;
        // @include set_font(25px, 600, 1);
      }
    }
    .register-speciality-list {
      .register-speciality-item {
        cursor: pointer;
        color: #252566;
        &--right {
          @include flex(flex-start, center);
        }
        @include flex(space-between, center);
        margin-bottom: convertToVw(20px);
        padding: convertToVw(20px) convertToVw(30px);
        border-radius: 10px;
        background-color: #fff;
        @include mq_min_750 {
          margin-bottom: 20px;
          padding: 20px 30px;
        }
        &::last-child {
          margin-bottom: 0;
        }
        .register-speciality-item-image {
          width: convertToVw(50px);
          height: convertToVw(50px);
          margin-right: convertToVw(20px);
          @include mq_min_750 {
            width: 50px;
            height: 50px;
            margin-right: 20px;
          }
          img {
            object-fit: contain;
          }
        }
        .register-speciality-item-text {
          //   @include set_font(convertToVw(25px), 400, 1);
          color: inherit;
          //   @include mq_min_750 {
          //     @include set_font(25px, 400, 1);
          //   }
          &.disabled {
            color: #828282;
          }
        }
      }
      .disable {
        cursor: default;
        // @include set_font(convertToVw(25px), 400, 1);
        // @include mq_min_750 {
        //   @include set_font(25px, 400, 1);
        // }
        background-color: #f5f5f5;
        color: #828282;
      }
    }
  }
}
