.wrapper.service {
  background-color: #d7dbe2;
  .service-detail {
    background-color: #d7dbe2;
    .service-detail-list {
      .service-detail-item {
        @include flex(flex-start, center);
        margin-bottom: convertToVw(30px);
        padding: convertToVw(15px);
        border-radius: 12px;
        background-color: #fff;
        transition: all 0.3s ease;
        @include mq_min_750 {
          margin-bottom: 30px;
          padding: 15px;
        }
        &:hover {
          color: #1a75bb;
        }
        .service-detail-item-icon {
          width: convertToVw(90px);
          height: convertToVw(90px);
          margin-right: convertToVw(30px);
          //   padding: convertToVw(30px);
          border-radius: 8px;
          overflow: hidden;
          @include mq_min_750 {
            width: 90px;
            height: 90px;
            margin-right: 30px;
            // padding: 30px;
          }
        }
        .service-detail-item-text {
          width: calc(100% - convertToVw(210px));
          //   min-height: convertToVw(60px);
          // display: block;
          //   @include set_font(convertToVw(35px), 500, convertToVw(35px));
          line-height: 1.2;
          @include truncate_text(2);
          @include mq_min_750 {
            width: calc(100% - 210px);
            // min-height: 60px;
            // @include set_font(35px, 500, 35px);
          }
        }
      }
    }
  }
}
