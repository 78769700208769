.wrapper.my-account {
  .header {
    .header-edit-button {
      padding: convertToVw(20px);
      @include set_font(convertToVw(35px), 300, 1);
      color: #fff;
      @include mq_min_750 {
        padding: 20px;
        @include set_font(35px, 300, 1);
      }
    }
  }
  .my-account-info {
    padding-bottom: convertToVw(50px);
    @include mq_min_750 {
      padding-bottom: 50px;
    }
    // margin-top: convertToVw(145px);
    // @include mq_min_750 {
    // 	margin-top: 145px;
    // }
    .avatar {
      width: convertToVw(250px);
      height: convertToVw(250px);
      border-radius: 50%;
      margin: 0 auto convertToVw(20px);
      overflow: hidden;
      // background-color: #a5a5a5;
      @include mq_min_750 {
        width: 250px;
        height: 250px;
        margin-bottom: 20px;
      }
    }
    .name {
      display: block;
      text-align: center;
      @include set_font(20px, 300, 1);
      color: #1a75bb;
      @include mq_min_750 {
        @include set_font(30px, 300, 1);
      }
    }
    .info {
      margin-top: convertToVw(60px);
      @include flex(flex-start, normal);
      flex-wrap: wrap;
      gap: convertToVw(20px);
      @include mq_min_750 {
        margin-top: 60px;
        gap: 20px;
      }
      .info-item {
        width: 100%;
        margin-bottom: convertToVw(25px);
        @include mq_min_750 {
          margin-bottom: 25px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: calc(50% - convertToVw(10px));
          @include mq_min_750 {
            width: calc(50% - 10px);
          }
        }
        .ant-form-item-label {
          .ant-form-item-required,
          label {
            font-weight: 200;
          }
        }
        .info-item-input {
          height: 100%;
          @include set_font(16px !important, 200, 1);
          padding: convertToVw(15px) convertToVw(10px);
          border: 1px solid #787d88;
          border-radius: 4px;
          @include mq_min_750 {
            @include set_font(23px !important, 200, 1);
            padding: 15px 10px;
          }
          &.button-upload {
            width: 100%;
            height: auto;
          }
          &.ant-picker {
            width: 100%;
            .ant-picker-input {
              input {
                @include set_font(16px, 200, 1);
                @include mq_min_750 {
                  @include set_font(23px, 200, 1);
                }
              }
            }
          }
          &.ant-select {
            .ant-select-selector {
              padding: 0;
              &::after {
                line-height: 1;
              }
              .ant-select-selection-search {
                .ant-select-selection-search-input {
                  height: 100%;
                }
              }
              //   @include set_font(convertToVw(25px), 400, 1);
              //   @include mq_min_750 {
              //     @include set_font(25px, 400, 1);
              //   }
              .ant-select-selection-item {
                line-height: normal;
              }
            }
          }
        }
        .info-item-note {
          display: block;
          margin-top: convertToVw(15px);
          line-height: 1.2;
          color: #1a75ba;
          font-style: italic;
          @include mq_min_750 {
            margin-top: 10px;
          }
        }
        .has-icon {
          position: relative;
          .info-item-icon {
            width: convertToVw(30px);
            height: convertToVw(30px);
            position: absolute;
            top: 50%;
            right: convertToVw(20px);
            transform: translateY(-50%);
            filter: brightness(0) saturate(100%) invert(60%) sepia(91%) saturate(529%) hue-rotate(173deg)
              brightness(102%) contrast(97%);
            @include mq_min_750 {
              width: 30px;
              height: 30px;
              right: 20px;
            }
          }
        }
        .info-item-error {
          display: inline-block;
          margin-top: convertToVw(10px);
          color: #f00;
          @include set_font(14px, 300, 1);
          @include mq_min_750 {
            margin-top: 10px;
            @include set_font(20px, 300, 1);
          }
        }
        .ant-form-item {
          .ant-upload {
            width: 100%;
          }
          .ant-upload-list {
            .ant-upload-list-item-container {
              &:first-child {
                margin-top: convertToVw(20px);
                @include mq_min_750 {
                  margin-top: 20px;
                }
              }
              .ant-upload-list-item {
                height: auto;
                padding: convertToVw(10px);
                border-radius: 6px;
                @include mq_min_750 {
                  padding: 10px;
                }
                .ant-upload-icon {
                  .anticon {
                    font-size: convertToVw(30px);
                    @include mq_min_750 {
                      font-size: 25px;
                    }
                  }
                }
                .ant-upload-list-item-actions {
                  .ant-upload-list-item-action.ant-btn {
                    opacity: 1;
                    .ant-btn-icon {
                      .anticon.anticon-delete {
                        font-size: convertToVw(30px);
                        @include mq_min_750 {
                          font-size: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .ant-select {
            &.ant-select-single {
              height: auto;
            }
          }
          .ant-select-selector {
            padding: 0;
          }
        }
        .document-list {
          display: flex;
          flex-wrap: wrap;
          gap: convertToVw(10px);
          @include mq_min_750 {
            gap: 10px;
          }
          .document-item {
            width: calc(50% - convertToVw(10px));
            padding: convertToVw(10px);
            border: 1px solid #787d88;
            border-radius: 4px;
            @include mq_min_750 {
              width: calc(50% - 10px);
              padding: 10px;
            }
          }
        }
        .info-item-label {
          display: block;
          padding-bottom: 8px;
        }
      }
    }
    .save-changes {
      margin-top: convertToVw(100px);
      @include mq_min_750 {
        margin-top: 100px;
      }
      .save-button {
        width: 100%;
        // @include set_font(convertToVw(30px), 400, 1);
        // @include mq_min_750 {
        //   @include set_font(30px, 400, 1);
        // }
      }
    }
  }
}
