.wrapper.transfer-info {
  font-family: "MyriadPro-Light", sans-serif;
  .transfer-info {
    // .transfer-info-title {
    //   position: relative;
    //   text-align: center;
    //   &::after {
    //     content: "";
    //     position: absolute;
    //     top: 50%;
    //     left: 0;
    //     width: 100%;
    //     height: 1px;
    //     background-color: #e5e5e5;
    //   }
    //   .transfer-info-title-text {
    //     position: relative;
    //     z-index: 1;
    //     padding: convertToVw(5px) convertToVw(10px);
    //     background-color: #f5f5f5;
    //     @include mq_min_750 {
    //       padding: 5px 10px;
    //     }
    //   }
    // }
    .transfer-info-content {
      .ebanking {
        .box-title.text-center {
          //   all: revert;
          height: auto;
          background: none;
          &::after {
            content: "";
            position: absolute;
            z-index: 0;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: #f5f5f5;
          }
          span {
            all: revert;
            position: relative;
            z-index: 1;
            padding: convertToVw(5px) convertToVw(10px);
            @include set_font(convertToVw(25px), 300, 1);
            background-color: #f5f5f5;
            @include mq_min_750 {
              padding: 5px 10px;
              @include set_font(25px, 300, 1);
            }
          }
        }
        .form-group {
          .bank-info-box {
            margin-left: unset;
            margin-right: unset;
            width: unset;
            .payment-title {
              margin-bottom: convertToVw(15px);
              @include set_font(convertToVw(25px), 300, 1);
              @include mq_min_750 {
                margin-bottom: 15px;
                @include set_font(25px, 300, 1);
              }
            }
            p:not(.payment-title) {
              margin-bottom: convertToVw(10px);
              @include mq_min_750 {
                margin-bottom: 10px;
              }
              strong {
                font-weight: 300;
              }
            }
            .qr-code {
              max-width: convertToVw(400px);
              margin: convertToVw(20px) auto 0;
              aspect-ratio: 2/3;
              @include mq_min_750 {
                max-width: 400px;
                margin: 20px auto 0;
              }
            }
          }
        }
      }
    }
  }
}
