.wrapper.add-relative {
  .add-relative {
    .relative-avt {
      @include flex(flex-start, center);
      margin-bottom: convertToVw(30px);
      @include mq_min_750 {
        margin-bottom: 30px;
      }
      .relative-avt-img {
        position: relative;
        width: convertToVw(100px);
        height: convertToVw(100px);
        margin-right: convertToVw(30px);
        border-radius: 50%;
        overflow: hidden;
        @include mq_min_750 {
          width: 100px;
          height: 100px;
          margin-right: 30px;
        }
        .relative-avt-upload {
          position: absolute;
          left: 0;
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }
      }
      .relative-avt-text {
        @include set_font(convertToVw(35px), 200, 1);
        @include mq_min_750 {
          @include set_font(25px, 200, 1);
        }
      }
    }
    .relative-info {
      @include flex(flex-start, flex-start);
      flex-wrap: wrap;
      .relative-info-item {
        width: 100%;
        margin-bottom: convertToVw(30px);
        @include mq_min_750 {
          margin-bottom: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &:nth-child(2),
        &:nth-child(3) {
          width: calc(50% - convertToVw(15px));
          @include mq_min_750 {
            width: calc(50% - 15px);
          }
        }
        &:nth-child(2) {
          margin-right: convertToVw(30px);
          @include mq_min_750 {
            margin-right: 30px;
          }
        }
        .relative-info-item-extend {
          margin: 0;
          .ant-form-item-control-input-content {
            .relative-info-text {
              margin-left: convertToVw(15px);
              @include mq_min_750 {
                margin-left: 15px;
              }
            }
            .relative-info-extend {
              display: inline-block;
              width: 30%;
              margin: 0;
            }
          }
        }
        .relative-info-input {
          width: 100%;
          height: 100%;
          padding: convertToVw(15px) convertToVw(10px);
          border: 1px solid #787d88;
          border-radius: 4px;
          @include set_font(16px, 200, 1);
          @include mq_min_750 {
            padding: 15px 10px;
            @include set_font(23px, 200, 1);
          }
          &.textarea {
            height: convertToVw(200px);
            resize: none;
            @include mq_min_750 {
              height: 200px;
            }
          }
          .ant-picker-input {
            input {
              @include set_font(16px, 200, 1);
              @include mq_min_750 {
                @include set_font(23px, 200, 1);
              }
            }
          }
          .ant-select-selector {
            padding: 0;
            &::after {
              line-height: 1;
            }
            .ant-select-selection-search {
              .ant-select-selection-search-input {
                height: 100%;
              }
            }
            .ant-select-selection-placeholder {
              line-height: normal;
            }
            .ant-select-selection-item {
              line-height: normal;
            }
          }
        }
        .relative-info-text {
          @include set_font(convertToVw(30px), 300, 1);
          @include mq_min_750 {
            @include set_font(25px, 300, 1);
          }
        }
        #relative-info-select {
          width: 100%;
          //   font-size: 25px;
          //   font-family: "MyriadPro-Light", sans-serif;
        }
        #blood_pressure,
        #blood_sugar {
          width: 30%;
          margin-right: convertToVw(15px);
          @include mq_min_750 {
            margin-right: 15px;
          }
        }
      }
    }
  }
  .has-bottom-button {
    padding-bottom: convertToVw(170px);
    @include mq_min_750 {
      padding-bottom: 170px;
    }
  }
  .save-change-button {
    width: 100%;
    min-height: convertToVw(120px);
    // @include flex(center, center);
    position: fixed;
    bottom: 0;
    padding: convertToVw(20px);
    transform: translateX(convertToVw(-30px));
    padding-right: 10%;
    border-top: 2px solid #efefef;
    background-color: #fff;
    @include mq_min_750 {
      max-width: 1000px;
      min-height: 120px;
      padding: 20px;
      transform: translateX((-30px));
    }
    .save-change {
      width: 100%;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      text-align: center;
      @include set_font(convertToVw(30px), 400, 1);
      @include mq_min_750 {
        @include set_font(30px, 400, 1);
        height: 75px;
      }
      &:hover {
        background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
      }
    }
  }
}
