// List post in free course category
.wrapper.free-course-post {
  .free-course-post {
    // padding-top: convertToVw(145px);
    // @include mq_min_750 {
    //   padding-top: 145px;
    // }
    .free-course-post-list {
      .free-course-post-item {
        @include flex(flex-start, normal);
        padding: convertToVw(20px) 0;
        border-bottom: 1px solid #9fa5ad;
        @include mq_min_750 {
          padding: 20px 0;
        }
        .free-course-post-item-image {
          width: convertToVw(200px);
          // height: convertToVw(100px);
          margin-right: convertToVw(30px);
          @include mq_min_750 {
            width: 200px;
            // height: 100px;
            margin-right: 30px;
          }
        }
        .free-course-post-item-content {
          width: calc(100% - convertToVw(230px));
          @include flex(space-between, flex-start);
          flex-direction: column;
          @include mq_min_750 {
            width: calc(100% - 230px);
          }
          .free-course-post-item-title {
            margin-bottom: convertToVw(20px);
            @include set_font(convertToVw(35px), 300, 1.2);
            @include truncate_text(3);
            @include mq_min_750 {
              margin-bottom: 20px;
              @include set_font(35px, 300, 1.2);
            }
          }
          .free-course-post-item-time {
            display: block;
            @include set_font(convertToVw(30px), 400, 1);
            color: #646466;
            @include mq_min_750 {
              @include set_font(30px, 400, 1);
            }
          }
        }
      }
    }
  }
}
