.header {
  width: 100%;
  max-width: convertToVw(1000px);
  min-height: 60px;
  @include flex(space-between, center);
  padding: convertToVw(25px) convertToVw(30px);
  position: sticky;
  top: 0;
  z-index: 2;
  background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
  @include mq_min_750 {
    max-width: 1000px;
    min-height: 90px;
    padding: 25px 30px;
  }
  .header-back-button {
    .header-back-icon {
      max-width: convertToVw(25px);
      max-height: convertToVw(40px);
      @include mq_min_750 {
        max-width: 25px;
        max-height: 40px;
      }
      img {
        object-fit: contain;
      }
    }
  }
  .header-text {
    width: 75%;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    @include set_font(18px, 200, 1.5);
    @include truncate_text(2);
    text-transform: capitalize;
    text-align: center;
    color: #fff;
    @include mq_min_750 {
      @include set_font(25px, 200, 1.5);
    }
    .header-text-item {
      line-height: 1.5;
      &:not(:last-child) {
        margin-bottom: convertToVw(5px);
      }
      @include mq_min_750 {
        &:not(:last-child) {
          margin-bottom: 5px;
        }
      }
    }
  }
  .header-call {
    max-width: convertToVw(40px);
    max-height: convertToVw(40px);
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7499%) hue-rotate(292deg) brightness(104%)
      contrast(104%);
    @include mq_min_750 {
      max-width: 40px;
      max-height: 40px;
    }
  }
}
.content-container {
  min-height: calc(100vh - 60px);
  // margin-top: convertToVw(145px);
  padding: 30px 15px;
  background-color: #fff;
  @include mq_min_750 {
    min-height: calc(100vh - 90px);
    // margin-top: 145px;
    padding: 30px;
  }
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  &.active {
    opacity: 1;
    visibility: visible;
  }
}
