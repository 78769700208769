.wrapper.select-bank {
  .select-bank {
    .img-container {
      width: convertToVw(30px);
      height: convertToVw(30px);
      @include mq_min_750 {
        width: 30px;
        height: 30px;
      }
    }
    .select-bank-search {
      position: relative;
      margin-bottom: convertToVw(30px);
      @include mq_min_750 {
        margin-bottom: 30px;
      }
      .select-bank-item {
        width: 100%;
        height: auto;
        .select-bank-search-icon {
          width: convertToVw(50px);
          height: convertToVw(50px);
          position: absolute;
          top: 50%;
          left: convertToVw(15px);
          transform: translateY(-50%);
          @include mq_min_750 {
            width: 50px;
            height: 50px;
            left: 15px;
          }
        }
      }
    }
    .select-bank-list {
      .select-bank-item {
        @include flex(space-between, center);
        margin-bottom: convertToVw(30px);
        // height: 45px;
        @include mq_min_750 {
          margin-bottom: 30px;
        }
        .select-bank-item-text {
          margin-right: convertToVw(20px);
          //   @include set_font(convertToVw(35px), 400, 1.1);
          @include truncate_text(1);
          @include mq_min_750 {
            margin-right: 20px;
            // @include set_font(35px, 400, 1.1);
          }
        }
        .select-bank-item-icon {
          width: convertToVw(50px);
          height: convertToVw(50px);
          @include mq_min_750 {
            width: 50px;
            height: 50px;
          }
          img {
            object-fit: contain;
          }
        }
      }
    }
    .box-confirm {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: convertToVw(30px);
      @include mq_min_750 {
        margin-top: 30px;
      }
      .confirm-btn {
        // width: 60%;
        margin: 20px auto 0 !important;
        display: block;
        height: auto;
        padding: convertToVw(15px) convertToVw(60px);
        text-align: center;
        // @include set_font(convertToVw(23px), 500, 1);
        @include mq_min_750 {
          padding: 15px 60px;
          //   @include set_font(23px, 500, 1);
        }
      }
    }
  }
}
