.wrapper.setting {
  .setting-list {
    // padding-top: convertToVw(145px);
    // @include mq_min_750 {
    //   padding-top: 145px;
    // }
    .setting-item {
      @include flex(space-between, center);
      padding: convertToVw(15px) 0;
      border-bottom: 1px solid #747e91;
      //   @include set_font(convertToVw(25px), 500, 1);
      transition: all 0.3s ease;
      cursor: pointer;
      @include mq_min_750 {
        padding: 15px 0;
        // @include set_font(25px, 500, 1);
      }
      &:hover {
        color: #1a75bb;
      }
      .setting-item-icon {
        width: convertToVw(25px);
        height: convertToVw(25px);
        @include mq_min_750 {
          width: 25px;
          height: 25px;
        }
        img {
          object-fit: contain;
        }
      }
    }
  }
}
