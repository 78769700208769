.wrapper.my-calendar {
  position: relative;
  .calendar {
    min-height: 100vh;
    .calendar-navbar {
      @include flex(flex-start, center);
      border-bottom: 1px solid #59657c;
      .navbar-item {
        width: calc(100% / 2);
        margin: convertToVw(10px) 0;
        padding: convertToVw(15px) convertToVw(10px);
        // @include set_font(convertToVw(30px), 400, 1);
        text-align: center;
        cursor: pointer;
        @include mq_min_750 {
          margin: 10px 0;
          padding: 15px 10px;
          //   @include set_font(30px, 400, 1);
        }
        &:first-child {
          border-right: 1px solid #59657c;
        }
        &.active {
          color: #1a75bb;
        }
      }
    }
    .calendar-tabs {
      .ant-tabs-nav {
        margin-bottom: 0;
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
              width: 50%;
              padding: convertToVw(20px) 0;
              justify-content: center;
              text-transform: capitalize;
              @include set_font(16px, 200, 1);
              @include mq_min_750 {
                padding: 15px 0;
                @include set_font(23px, 200, 1);
              }
              .ant-tabs-tab-btn {
                font-size: inherit;
              }
            }
            .ant-tabs-ink-bar {
              width: 50% !important;
              left: 0 !important;
              transform: translateX(0) !important;
              &.right {
                left: 50% !important;
              }
            }
          }
        }
        .ant-tabs-nav-operations {
          display: none;
        }
      }
    }
    .calendar-list {
      padding-bottom: convertToVw(50px);
      @include mq_min_750 {
        padding-bottom: 50px;
      }
      .calendar-item {
        cursor: pointer;
        padding: convertToVw(25px) convertToVw(30px);
        border-bottom: 1px solid #747e91;
        @include mq_min_750 {
          padding: 25px 30px;
        }
        .calendar-item-info {
          @include flex(flex-start, flex-start);
          .calendar-icon {
            width: convertToVw(50px);
            height: convertToVw(50px);
            margin-right: convertToVw(20px);
            @include mq_min_750 {
              width: 50px;
              height: 50px;
              margin-right: 20px;
            }
            &.calendar-schedule {
              filter: brightness(0) saturate(100%) invert(44%) sepia(98%) saturate(502%) hue-rotate(315deg)
                brightness(98%) contrast(92%);
            }
            &.calendar-complete {
              filter: brightness(0) saturate(100%) invert(64%) sepia(93%) saturate(271%) hue-rotate(164deg)
                brightness(91%) contrast(90%);
            }
            img {
              object-fit: contain;
            }
          }
          .calendar-info {
            width: calc(100% - convertToVw(70px));
            @include mq_min_750 {
              width: calc(100% - 70px);
            }
            &:hover {
              color: #000;
            }
            .calendar-date {
              display: block;
              margin-bottom: convertToVw(10px);
              font-weight: 300;
              color: #1a75bb;
              @include mq_min_750 {
                margin-bottom: 10px;
              }
            }
            .calendar-table {
              margin-bottom: convertToVw(40px);
              @include mq_min_750 {
                margin-bottom: 40px;
              }
              .calendar-key,
              .calendar-value {
                padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
                line-height: 1.4;
                @include mq_min_750 {
                  padding: 15px 10px 15px 0;
                }
              }
              .calendar-key {
                min-width: 40%;
              }
              .calendar-value {
                min-width: 60%;
                padding-left: convertToVw(15px);
                @include mq_min_750 {
                  padding-left: 15px;
                }
                &.calendar-price {
                  font-weight: 300;
                  color: #d42727;
                }
              }
            }
          }
        }
        .calendar-item-action {
          @include flex(flex-start, center);
          gap: 10px;
          margin-left: convertToVw(70px);
          @include mq_min_750 {
            margin-left: 70px;
          }
          .calendar-contact {
            padding: convertToVw(10px) convertToVw(25px);
            border: 1px solid transparent;
            border-radius: 50px;
            transition: all 0.3s ease;
            @include set_font(convertToVw(25px), 400, 1);
            @include mq_min_750 {
              padding: 10px 25px;
              @include set_font(25px, 400, 1);
            }
            &:hover {
              border: 1px solid #1a75bb;
              background: transparent;
              color: #1a75bb;
            }
          }
        }
        .partner-info {
          width: 70%;
          max-height: 80%;
          // height: 50%;
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          opacity: 0;
          visibility: hidden;
          padding: convertToVw(50px);
          border-radius: 10px;
          background-color: #fff;
          z-index: 2;
          transition: all 0.3s ease;
          overflow: auto;
          @include mq_min_750 {
            width: 50%;
            max-width: 680px;
            padding: 50px;
          }
          &.active {
            opacity: 1;
            visibility: visible;
          }
          .partner-close {
            display: block;
            margin-left: auto;
            padding: convertToVw(15px);
            @include set_font(convertToVw(30px), 400, 1);
            @include mq_min_750 {
              padding: 15px;
              @include set_font(30px, 400, 1);
            }
          }
          .partner-avt {
            width: convertToVw(150px);
            height: convertToVw(150px);
            margin: 0 auto convertToVw(20px);
            border-radius: 50%;
            @include mq_min_750 {
              width: 150px;
              height: 150px;
              margin: 0 auto 20px;
            }
          }
          .partner-name {
            display: block;
            margin-bottom: convertToVw(10px);
            text-align: center;
            @include set_font(convertToVw(30px), 300, 1);
            color: #1a75bb;
            @include mq_min_750 {
              margin-bottom: 10px;
              @include set_font(30px, 300, 1);
            }
          }
          .partner-speciality {
            display: block;
            margin-bottom: convertToVw(30px);
            text-align: center;
            @include set_font(convertToVw(25px), 400, 1);
            @include mq_min_750 {
              margin-bottom: 30px;
              @include set_font(25px, 400, 1);
            }
          }
          .star {
            @include flex(center, center);
            margin-bottom: convertToVw(30px);
            @include mq_min_750 {
              margin-bottom: 30px;
            }
            .star-item {
              width: convertToVw(70px);
              height: convertToVw(70px);
              margin-right: convertToVw(5px);
              filter: brightness(0) saturate(100%) invert(85%) sepia(89%) saturate(1653%) hue-rotate(338deg)
                brightness(92%) contrast(96%);
              @include mq_min_750 {
                width: 70px;
                height: 70px;
                margin-right: 5px;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
          .partner-phone {
            @include flex(center, center);
            padding: convertToVw(15px) convertToVw(20px);
            border-radius: 8px;
            @include set_font(convertToVw(25px), 400, 1);
            background-color: #3aa027;
            color: #fff;
            @include mq_min_750 {
              padding: 15px 20px;
              @include set_font(25px, 400, 1);
            }
            .partner-phone-icon {
              width: convertToVw(25px);
              height: convertToVw(25px);
              margin-right: convertToVw(15px);
              filter: brightness(0) saturate(100%) invert(100%) sepia(68%) saturate(2%) hue-rotate(57deg)
                brightness(102%) contrast(101%);
              @include mq_min_750 {
                width: 25px;
                height: 25px;
                margin-right: 15px;
              }
            }
          }
        }
      }
    }
  }
}
.partner-evaluate {
  .partner-evaluate-body {
    .partner-avatar {
      width: convertToVw(150px);
      height: convertToVw(150px);
      margin: 0 auto convertToVw(20px);
      border-radius: 50%;
      overflow: hidden;
      @include mq_min_750 {
        width: 150px;
        height: 150px;
        margin: 0 auto 20px;
      }
    }
    .partner-name {
      display: block;
      margin-bottom: convertToVw(10px);
      text-align: center;
      @include set_font(convertToVw(25px), 300, 1);
      color: #1a75bb;
      @include mq_min_750 {
        margin-bottom: 10px;
        @include set_font(25px, 300, 1);
      }
    }
    .partner-speciality {
      display: block;
      margin-bottom: convertToVw(30px);
      text-align: center;
      @include set_font(convertToVw(25px), 400, 1);
      @include mq_min_750 {
        margin-bottom: 30px;
        @include set_font(25px, 400, 1);
      }
    }
    // }
    .partner-evaluate-text {
      display: block;
      margin-bottom: convertToVw(10px);
      text-align: center;
      @include set_font(convertToVw(25px), 400, 1);
      @include mq_min_750 {
        margin-bottom: 10px;
        @include set_font(25px, 400, 1);
      }
    }
    .partner-evaluate-star {
      @include flex(center, center);
      margin-bottom: convertToVw(30px);
      @include mq_min_750 {
        margin-bottom: 30px;
      }
      .star-item {
        width: convertToVw(70px);
        height: convertToVw(70px);
        margin-right: convertToVw(5px);
        @include mq_min_750 {
          width: 70px;
          height: 70px;
          margin-right: 5px;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .partner-evaluate-comment {
      margin-bottom: convertToVw(20px);
      padding: convertToVw(20px);
      border-radius: 5px;
      @include set_font(convertToVw(30px), 400, 1);
      resize: none;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 20px;
        @include set_font(30px, 400, 1);
      }
    }
    .partner-evaluate-submit {
      width: 100%;
      padding: convertToVw(15px) convertToVw(20px);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      @include set_font(convertToVw(25px), 400, 1);
      @include mq_min_750 {
        padding: 15px 20px;
        @include set_font(25px, 400, 1);
      }
    }
  }
}
