.wrapper.bank-info {
  .bank-info {
    .bank-info-title {
      margin-bottom: convertToVw(30px);
      //   @include set_font(convertToVw(40px), 600, 1);
      font-weight: 300;
      @include mq_min_750 {
        margin-bottom: 30px;
        // @include set_font(40px, 600, 1);
      }
    }
    .bank-info-form {
      .bank-info-form-item {
        margin-bottom: convertToVw(30px);
        @include mq_min_750 {
          margin-bottom: 30px;
        }
        .bank-info-form-item-label {
          display: block;
          margin-bottom: convertToVw(15px);
          //   @include set_font(convertToVw(35px), 400, 1);
          @include mq_min_750 {
            margin-bottom: 15px;
            // @include set_font(30px, 400, 1);
          }
        }
        .bank-info-form-item-input {
          padding: convertToVw(15px) convertToVw(20px);
          border: 1px solid #7f7f7f;
          border-radius: 5px;
          //   @include set_font(convertToVw(35px), 400, 1);
          @include mq_min_750 {
            padding: 15px 20px;
            // @include set_font(30px, 400, 1);
          }
        }
        .bank-info-form-item-link {
          display: block;
          padding: convertToVw(15px) convertToVw(20px);
          border: 1px solid #7f7f7f;
          border-radius: 5px;
          //   @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            padding: 15px 20px;
            // @include set_font(25px, 400, 1);
          }
        }
      }
      .bank-info-form-button {
        width: 100%;
        margin-top: convertToVw(30px);
        // @include set_font(convertToVw(35px), 400, 1);
        @include mq_min_750 {
          margin-top: 30px;
          //   @include set_font(30px, 400, 1);
        }
      }
      .btn-submit {
        // width: 60%;
        margin: 20px auto 0 !important;
        display: block;
        height: auto;
        padding: convertToVw(15px) convertToVw(60px);
        // @include set_font(convertToVw(23px), 500, 1);
        @include mq_min_750 {
          padding: 15px 60px;
          //   @include set_font(23px, 500, 1);
        }
        &:hover {
          background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
        }
        &:disabled:hover {
          background: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
