/* Custom Ant Design */
.App {
  .ant-input-affix-wrapper {
    .ant-input {
      @include set_font(16px, 200, 1);
      @include mq_min_750 {
        @include set_font(23px !important, 200, 1);
      }
    }
  }
}
.ant-select {
  .ant-select-selection-item {
    font-weight: 200;
  }
}
.ant-select-dropdown {
  .ant-select-item-option-content {
    padding: 5px 0;
  }
}
.ant-form-item-label {
  label {
    font-family: "Be Vietnam Pro", serif;
    @include set_font(16px !important, 200, 1);
    @include mq_min_750 {
      @include set_font(23px !important, 200, 1);
    }
  }
}
.ant-form-item-explain-error {
  margin: convertToVw(10px) 0 0;
  @include set_font(14px, 200, 1.2);
  @include mq_min_750 {
    margin: 10px 0 0;
    @include set_font(20px, 200, 1);
  }
}

.label-require {
  margin: convertToVw(20px) 0 convertToVw(30px);
  // font-size: 14px;
  @include set_font(16px, 200, 1);
  color: #ff0000;
  @include mq_min_750 {
    margin: 20px 0 30px;
    @include set_font(23px, 200, 1);
  }
}
.ant-form-item {
  margin-bottom: 0;
  //   margin: 0 auto !important;
}

.ant-notification {
  .ant-notification-notice-wrapper {
    .ant-notification-notice-message {
      margin-bottom: 0 !important;
      line-height: 1.2;
    }
  }
}

/*  */
.label-require {
  //   font-size: 14px;
  color: #ff0000;
  margin: 10px 0px 15px;
}

/* Custom over loading */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 67, 67, 0.5);
  @include flex(center, center);
  z-index: 999999;
  user-select: none;
  .ant-spin-dot-item {
    background-color: inear-gradient(to right, #4f5bb4, #359dd4) !important;
  }
}

/* Clear icon input type date */
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* Custom font size select */
.ant-modal-root {
  .ant-modal-title {
    @include set_font(convertToVw(27px), 300, 1);
    @include mq_min_750 {
      @include set_font(25px, 300, 1);
    }
  }
  .ant-modal-body {
    * {
      @include set_font(16px, 200, 1);
      @include mq_min_750 {
        @include set_font(23px, 200, 1);
      }
    }
  }
  .ant-modal-footer {
    .ant-btn {
      height: auto;
      padding: convertToVw(10px) convertToVw(20px);
      @include set_font(16px, 200, 1);
      @include mq_min_750 {
        padding: 10px 20px;
        @include set_font(23px, 200, 1);
      }
    }
  }
}

/* DatePicker */
.ant-picker-dropdown {
  * {
    font-size: 16px;
  }
}
