// Định nghĩa các mixin cho media queries
// @mixin mq_max_768 {
//   @media screen and (max-width: 768px) {
//     @content;
//   }
// }

// @mixin mq_max_480 {
//   @media screen and (max-width: 480px) {
//     @content;
//   }
// }

// @mixin mq_min_480_max_750 {
//   @media screen and (min-width: 480px) and (max-width: 750px) {
//     @content;
//   }
// }

.container-modal--otp {
  background-color: #f5f5f5;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
  border: 1px solid #ccc;
  //   padding: 1.25rem;
  padding: convertToVw(15px);
  border-radius: 6px;
  @include mq_min_750 {
    max-width: 500px;
    padding: 15px;
  }
  //   margin-top: 25vh;

  //   @include mq_max_768 {
  //     width: 95%;
  //     margin-top: 20vh;
  //     padding: 1rem;
  //   }

  //   @include mq_max_480 {
  //     width: 80%;
  //     left: 50%;
  //     transform: translateX(-50%);
  //   }

  //   @include mq_min_480_max_750 {
  //     width: 85%;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     padding: 1rem;
  //   }
}

.title-modal--otp {
  //   font-weight: bold;
  margin-bottom: convertToVw(10px);
  text-align: center;
  @include mq_min_750 {
    margin-bottom: 10px;
  }
  //   font-size: 20px;
  //   margin-bottom: 1.25rem;

  //   @include mq_max_768 {
  //     font-size: 1.25rem; /* Smaller font size for smaller screens */
  //   }

  //   @include mq_max_480 {
  //     font-size: 1rem;
  //   }

  //   @include mq_min_480_max_750 {
  //     font-size: 1.1rem;
  //   }
}

.subtitle-modal--otp {
  display: block;
  margin: 0 auto;
  padding: convertToVw(10px) convertToVw(15px);
  color: #1a75bb;
  @include mq_min_750 {
    padding: 10px 15px;
  }
  &:disabled {
    border-color: #ccc;
    color: #ccc;
    cursor: not-allowed;
  }
}

.otp-input-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}

.otp-input {
  width: convertToVw(50px);
  height: convertToVw(50px);
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  @include mq_min_750 {
    width: 50px;
    height: 50px;
  }
  &:focus {
    outline: none;
    border-color: #00f;
  }
}

.resend-container {
  width: 100%;
  margin-top: convertToVw(30px);
  text-align: center;
  @include mq_min_750 {
    margin-top: 30px;
  }
}

.resend-link {
  margin-top: convertToVw(10px);
  @include mq_min_750 {
    margin-top: 10px;
  }
}

.btn-confirm {
  width: auto;
  margin-top: convertToVw(20px);
  padding: convertToVw(15px) convertToVw(20px);
  @include mq_min_750 {
    margin-top: 20px;
    padding: 15px 20px;
  }
  &:hover {
    &:not(:disabled) {
      background: linear-gradient(to right, #4f5bb4, #359dd4) border-box !important;
    }
  }
}

.close-button {
  display: block;
  margin-left: auto;
  padding: convertToVw(5px) convertToVw(10px);
  @include mq_min_750 {
    padding: 5px 10px;
  }
}
