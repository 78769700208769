.wrapper.promotion {
  .promotion-list {
    background-color: #d7dbe2;
    .promotion-item {
      @include flex(flex-start, normal);
      margin-bottom: convertToVw(20px);
      padding: convertToVw(15px);
      border-radius: 12px;
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 15px;
      }
      .promotion-item-icon {
        width: convertToVw(200px);
        // height: convertToVw(100px);
        aspect-ratio: 3/2;
        margin-right: convertToVw(20px);
        border-radius: 8px;
        overflow: hidden;
        @include mq_min_750 {
          width: 200px;
          //   height: 100px;
          margin-right: 20px;
        }
        // img {
        //   object-fit: contain;
        // }
      }
      .promotion-item-content {
        width: calc(100% - convertToVw(200px) - convertToVw(20px));
        @include mq_min_750 {
          width: calc(100% - 200px - 20px);
        }
        .promotion-item-title {
          margin-bottom: convertToVw(15px);
          font-weight: 400;
          line-height: 1.5;
          @include truncate_text(2);
          color: #1a75bb;
          @include mq_min_750 {
            margin-bottom: 15px;
          }
        }
        .promotion-item-description {
          line-height: 1.2;
          @include truncate_text(3);
        }
      }
    }
  }
}
.wrapper.promotion-detail {
  .promotion-detail-text {
    * {
      margin: revert;
      padding: revert;
      border: revert;
      vertical-align: revert;
      @include set_font(convertToVw(25px) !important, 200, 1.2);
      font-family: "Be Vietnam Pro", sans-serif !important;
      @include mq_min_750 {
        @include set_font(23px !important, 200, 1.2);
      }
      background-color: #fff !important;
    }
    img {
      width: 100% !important;
      margin: 10px 0;
    }
    ol,
    ul {
      list-style: revert;
    }
  }
}
