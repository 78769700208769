.wrapper.register-service {
  .register-service {
    min-height: 100vh;
    background-color: #d7dbe2;
    .speciality-selected {
      @include flex(flex-start, center);
      margin-bottom: convertToVw(20px);
      padding: convertToVw(20px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 20px 30px;
      }
      .speciality-selected-icon {
        width: convertToVw(60px);
        height: convertToVw(60px);
        margin-right: convertToVw(20px);
        padding: convertToVw(10px);
        border-radius: 50%;
        background-color: #33a3db;
        @include mq_min_750 {
          width: 60px;
          height: 60px;
          margin-right: 20px;
          padding: 10px;
        }
        img {
          object-fit: contain;
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(104%)
            contrast(103%);
        }
      }
      .speciality-selected-text {
        // @include set_font(convertToVw(30px), 600, 1);
        font-weight: 300;
        color: #1b75ba;
        // @include mq_min_750 {
        //   @include set_font(30px, 600, 1);
        // }
      }
    }
    .requirement-degree {
      margin-bottom: convertToVw(20px);
      padding: convertToVw(20px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 20px 30px;
      }
      .requirement-degree-title {
        margin-bottom: convertToVw(15px);
        font-weight: 300;
        // @include set_font(convertToVw(30px), 600, 1);
        // @include mq_min_750 {
        //   @include set_font(30px, 600, 1);
        // }
      }
      .requirement-degree-list {
        margin-left: convertToVw(20px);
        list-style-type: "- ";
        @include mq_min_750 {
          margin-left: 20px;
        }
        li {
          margin-bottom: convertToVw(10px);
          //   @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            margin-bottom: 10px;
            // @include set_font(25px, 400, 1);
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .select-service {
      padding: convertToVw(20px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        padding: 20px 30px;
      }
      .select-service-title {
        margin-bottom: convertToVw(20px);
        // @include set_font(convertToVw(30px), 600, 1);
        font-weight: 300;
        @include mq_min_750 {
          margin-bottom: 20px;
          //   @include set_font(30px, 600, 1);
        }
      }
      .select-service-list {
        .select-service-item {
          @include flex(flex-start, center);
          padding: convertToVw(15px) 0;
          border-bottom: 1px solid #aaaaaa;
          background-color: #fff;
          //   cursor: pointer;
          @include mq_min_750 {
            padding: 15px 0;
          }
          .select-service-item-checkbox {
            width: auto;
            margin-right: convertToVw(20px);
            cursor: pointer;
            @include mq_min_750 {
              margin-right: 20px;
            }
          }
          .select-service-item-text {
            width: 100%;
            // padding: convertToVw(15px) 0;
            // @include set_font(convertToVw(35px), 400, 1);
            @include truncate_text(1);
            line-height: 1.2;
            color: #252566;
            cursor: pointer;
            // @include mq_min_750 {
            //   padding: 15px 0;
            //   @include set_font(30px, 400, 1);
            // }
            &.disabled {
              color: #828282;
            }
          }
        }
      }
    }
  }
  .register-button-wrapper {
    width: 100%;
    // min-height: convertToVw(120px);
    // @include flex(center, center);
    position: fixed;
    bottom: 0;
    padding: convertToVw(20px);
    // border-top: 2px solid #efefef;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    background-color: #fff;
    @include mq_min_750 {
      max-width: 1000px;
      //   min-height: 120px;
      padding: 20px;
    }
    .register-button {
      width: 100%;
      // box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      text-align: center;
      //   @include set_font(convertToVw(35px), 400, 1);
      //   @include mq_min_750 {
      //     @include set_font(30px, 400, 1);
      //   }
    }
  }
}
