// Free course category
.wrapper.free-course {
  .free-course {
    // padding-top: convertToVw(145px);
    // @include mq_min_750 {
    //   padding-top: 145px;
    // }
    .free-course-list {
      .free-course-item {
        @include flex(space-between, center);
        padding: convertToVw(15px) 0;
        border-bottom: 1px solid #9fa5ad;
        // @include set_font(convertToVw(35px), 400, 1);
        transition: all 0.3s ease;
        @include mq_min_750 {
          padding: 15px 0;
          //   @include set_font(30px, 400, 1);
        }
        &:hover {
          color: #1a75bb;
        }
        .free-course-item-icon {
          width: convertToVw(25px);
          height: convertToVw(25px);
          @include mq_min_750 {
            width: 25px;
            height: 25px;
          }
          img {
            object-fit: contain;
          }
        }
      }
    }
  }
}
