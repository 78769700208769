.wrapper.report {
  .report {
    background-color: #f2f1f6;
    .report-item {
      margin-bottom: convertToVw(20px);
      padding: convertToVw(20px) convertToVw(30px);
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 20px 30px;
      }
      .report-item-title {
        margin-bottom: convertToVw(25px);
        @include mq_min_750 {
          margin-bottom: 25px;
        }
      }
      .report-item-count {
        font-weight: 300;
      }
    }
  }
}
