.wrapper.terms-of-use,
.wrapper.privacy-policy {
  .terms-of-use,
  .privacy-policy {
    .logo {
      max-width: convertToVw(300px);
      margin: 0 auto convertToVw(100px);
      @include mq_min_750 {
        max-width: 300px;
        margin: 0 auto 100px;
      }
    }
    .title {
      margin-bottom: convertToVw(30px);
      font-weight: 300;
      color: #1d76ba;
      @include mq_min_750 {
        margin-bottom: 30px;
      }
    }
    .text {
      text-align: justify;
      .footer-list {
        margin-top: 40px;
        display: flex;
        justify-content: space-around;
        .foooter-item {
          align-items: center;
          padding: 15px 0;
          @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            padding: 30px 0;
            @include set_font(30px, 400, 1);
          }
          .foooter-icon {
            width: convertToVw(35px);
            height: convertToVw(35px);
            @include mq_min_750 {
              width: 35px;
              height: 35px;
            }
            img {
              object-fit: contain;
            }
          }
        }
      }
      //   @include mq_min_750 {
      //     @include set_font(25px, 500, 1.2);
      //   }
      p {
        line-height: 1.2;
        margin-bottom: convertToVw(35px);
        @include mq_min_750 {
          margin-bottom: 35px;
        }
        b {
          font-weight: 300;
        }
      }
      ul {
        margin-bottom: convertToVw(35px);
        padding-left: convertToVw(60px);
        @include mq_min_750 {
          margin-bottom: 35px;
          padding-left: 60px;
        }
        li {
          display: list-item;
          list-style: disc;
          margin-bottom: convertToVw(10px);
          line-height: 1.2;
          @include mq_min_750 {
            margin-bottom: 10px;
          }
        }
        li::marker {
          color: #1d76ba;
        }
      }
    }
  }
  .text-bottom {
    @include set_font(25px, 500, 1.2);
    @include mq_min_750 {
      @include set_font(30px, 500, 1.2);
    }
    display: flex;
    justify-content: end;
    margin-top: 40px;
    color: blue;
  }
}
