.wrapper.register {
  //   background-color: #d7dbe2;
  .register {
    // padding: convertToVw(50px) 0 convertToVw(30px);
    // @include mq_min_750 {
    //   padding: 50px 0 30px;
    // }
    .register-icon {
      max-width: convertToVw(150px);
      max-height: convertToVw(150px);
      margin: 0 auto;
      @include mq_min_750 {
        max-width: 150px;
        max-height: 150px;
      }
    }
    .register-form {
      margin-top: convertToVw(50px);
      color: #8e8e8e;
      @include mq_min_750 {
        margin-top: 50px;
      }
      .register-form-group {
        margin-bottom: convertToVw(30px) !important;
        @include mq_min_750 {
          margin-bottom: 30px !important;
        }
        .register-form-label {
          display: block;
          margin-bottom: convertToVw(10px);
          @include mq_min_750 {
            margin-bottom: 10px;
          }
        }
        .register-form-input {
          padding: convertToVw(15px);
          border: 1px solid #a2a2a2;
          border-radius: 4px;
          //   @include set_font(16px, 200, 1);
          //   @include set_font(convertToVw(25px), 500, convertToVw(40px));
          @include mq_min_750 {
            padding: 15px;
            // @include set_font(23px, 400, 1);
            // @include set_font(25px, 500, 40px);
          }
        }
        .register-terms-checkbox {
          @include set_font(convertToVw(25px), 400, 1);
          @include mq_min_750 {
            @include set_font(23px, 400, 1);
            .ant-checkbox {
              .ant-checkbox-inner {
                width: convertToVw(25px);
                height: convertToVw(25px);
                @include mq_min_750 {
                  width: 23px;
                  height: 23px;
                }
              }
            }
          }
        }
        .ant-form-item-explain-error {
          margin-bottom: convertToVw(30px);
          @include mq_min_750 {
            margin-bottom: 30px;
          }
        }
      }
      .button-register {
        display: block;
        min-width: convertToVw(360px);
        margin: 0 auto;
        padding: convertToVw(15px) convertToVw(30px);
        @include mq_min_750 {
          min-width: 360px;
          padding: 15px 30px;
        }
      }
    }
    .return-login {
      display: block;
      margin-top: convertToVw(30px);
      padding: convertToVw(15px);
      text-align: center;
      //   @include set_font(convertToVw(25px), 500, convertToVw(23px));
      color: #4260a2;
      @include mq_min_750 {
        margin-top: 30px;
        padding: 15px;
        // @include set_font(25px, 500, 23px);
      }
    }
    .term-of-use {
      display: block;
      margin-top: convertToVw(60px);
      padding: convertToVw(15px);
      text-align: center;
      //   @include set_font(convertToVw(25px), 500, convertToVw(23px));
      color: #6d6c6c;
      @include mq_min_750 {
        margin-top: 60px;
        padding: 15px;
        // @include set_font(25px, 500, 23px);
      }
    }
  }
}
