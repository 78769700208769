*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Thin.ttf");
  font-weight: 100;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-ExtraLight.ttf");
  font-weight: 200;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-ExtraBold.ttf");
  font-weight: 800;
}
@font-face {
  font-family: "Be Vietnam Pro";
  src: url("../../fonts/BeVietnamPro-Black.ttf");
  font-weight: 900;
}
body {
  font-family: "Be Vietnam Pro", sans-serif;
}
a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
input,
textarea {
  width: 100%;
  margin: 0;
  outline: 0;
}
button {
  padding: 0;
  border: 0;
  outline: 0;
  cursor: pointer;
  background: 0;
}
.wrapper {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  @include mq_min_750 {
    max-width: 1000px;
  }
}
.container {
  margin: 0 auto;
  padding: convertToVw(50px) convertToVw(30px);
  max-width: convertToVw(680px);
  @include mq_min_750 {
    padding: 50px 30px;
    max-width: 680px;
  }
}
.primary {
  height: auto;
  padding: convertToVw(20px) convertToVw(60px);
  border: 1px solid transparent;
  border-radius: 4px;
  background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
  background-repeat: no-repeat;
  color: #fff;
  //   @include set_font(convertToVw(25px), 500, 1);
  //   font-weight: 400;
  @include mq_min_750 {
    padding: 20px 60px;
    // @include set_font(25px, 500, 1);
  }
}
.secondary {
  border: 1px solid #4260a2;
  border-radius: 4px;
  color: #4260a2;
}
div,
a,
button,
label {
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  @include mq_min_750 {
    user-select: initial;
    -webkit-tap-highlight-color: initial;
    -webkit-touch-callout: initial;
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
  }
}
.no-scroll {
  overflow: hidden;
}
div,
span,
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
label,
input,
textarea {
  font-family: "Be Vietnam Pro", sans-serif;
  @include set_font(16px, 200, 1);
  @include mq_min_750 {
    @include set_font(23px, 200, 1);
  }
  &::placeholder {
    @include set_font(16px, 200, 1);
    @include mq_min_750 {
      @include set_font(23px, 200, 1);
    }
  }
}

/* Custom New */

.ant-notification-notice-message {
  margin-bottom: 0 !important;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 67, 67, 0.5);
  @include flex(center, center);
  z-index: 999999;
  user-select: none;
  .ant-spin-dot-item {
    background-color: inear-gradient(to right, #4f5bb4, #359dd4) !important;
  }
}

.slick-dots {
  z-index: 0 !important;
}
