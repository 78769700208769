.wrapper.home {
  position: relative;
  overflow: hidden;
  clip-path: border-box;
  .loading {
    height: 100vh;
    padding: 150px 0;
    .loading-logo {
      max-width: convertToVw(462px);
      max-height: convertToVw(427px);
      margin: 0 auto;
      @include mq_min_750 {
        max-width: 462px;
        max-height: 427px;
      }
    }
    .loading-bar {
      max-width: convertToVw(300px);
      height: convertToVw(8px);
      position: relative;
      margin: convertToVw(100px) auto 0;
      background-color: #b0e1ff;
      &::before {
        content: "";
        position: absolute;
        right: auto;
        left: 0;
        height: 100%;
        background-color: #1a75bb;
        animation: lineLoading 1.5s forwards infinite linear;
      }
      @include mq_min_750 {
        max-width: 300px;
        height: 8px;
        margin: 100px auto 0;
      }
      @keyframes lineLoading {
        0% {
          right: 100%;
        }

        50% {
          right: 0;
          left: 0;
        }

        100% {
          right: 0;
          left: 100%;
        }
      }
    }
  }
  .header-createpass {
    background: #40bdff !important;
  }
  .header {
    width: 100%;
    max-width: convertToVw(1000px);
    min-height: convertToVw(90px);
    max-height: convertToVw(90px);
    @include flex(space-between, center);
    padding: convertToVw(25px) convertToVw(30px);
    position: fixed;
    top: 0;
    z-index: 1;
    background: #fff;
    @include mq_min_750 {
      max-width: 1000px;
      min-height: 90px;
      max-height: 90px;
      padding: 25px 30px;
    }
    .header-hamburger {
      .header-hamburger-icon {
        max-width: convertToVw(40px);
        max-height: convertToVw(40px);
        @include mq_min_750 {
          max-width: 40px;
          max-height: 40px;
        }
        img {
          object-fit: contain;
        }
      }
    }
    .header-back--icon {
      margin-top: 10px;
      width: 26px;
      @include mq_min_750 {
        width: 36px;
      }
    }
    .header-logo {
      max-width: convertToVw(255px);
      max-height: convertToVw(40px);
      @include mq_min_750 {
        max-width: 255px;
        max-height: 40px;
      }
      img {
        max-height: convertToVw(40px);
        object-fit: contain;
        @include mq_min_750 {
          max-height: 40px;
        }
      }
    }
    .header-call {
      max-width: convertToVw(40px);
      max-height: convertToVw(40px);
      filter: none;
      @include mq_min_750 {
        max-width: 40px;
        max-height: 40px;
      }
    }
  }
  .menu {
    min-width: 70%;
    min-height: 100vh;
    padding: convertToVw(40px) convertToVw(25px) 0;
    position: fixed;
    z-index: 16;
    top: 0;
    // left: 0;
    background-color: #59657d;
    transform: translateX(-100%);
    transition: all 0.3s ease;
    @include mq_min_750 {
      min-width: 40%;
      max-width: 700px;
      padding: 40px 25px 0;
    }
    &.active {
      transform: translateX(0);
    }
    .menu-account {
      @include flex(flex-start, center);
      padding-bottom: 10px;
      position: sticky;
      .menu-account-image {
        max-width: convertToVw(80px);
        max-height: convertToVw(80px);
        margin-right: convertToVw(20px);
        aspect-ratio: 1;
        @include mq_min_750 {
          max-width: 80px;
          max-height: 80px;
          margin-right: 20px;
        }
        img {
          border-radius: 50%;
        }
      }
      .menu-account-text {
        // @include set_font(convertToVw(25px), 400, convertToVw(30px));
        // font-weight: 600;
        color: #fff;
        // @include mq_min_750 {
        //   @include set_font(25px, 400, 30px);
        // }
      }
    }
    .menu-list {
      max-height: calc(100vh - convertToVw(100px) - convertToVw(55px));
      padding: convertToVw(40px) 0;
      // padding-bottom: convertToVw(50px);
      overflow: auto;
      // box-sizing: c-box;
      @include mq_min_750 {
        max-height: calc(100vh - 100px - 40px);
        padding: 40px 0;
        // padding-bottom: 50px;
      }
      &::-webkit-scrollbar {
        width: convertToVw(10px);
        @include mq_min_750 {
          width: 10px;
        }
      }
      &::-webkit-scrollbar-thumb {
        background-color: #444d5e;
        border-radius: 10px;
      }
      .menu-list-wrapper {
        // @include mq_min_750 {
        // 	height: calc(100vh + 900px);
        // }
        .menu-item {
          position: relative;
          @include flex(flex-start, center);
          margin-bottom: convertToVw(25px);
          padding: convertToVw(20px) 0;
          @include mq_min_750 {
            margin-bottom: 25px;
            padding: 20px 0;
          }
          .menu-item-icon {
            width: convertToVw(45px);
            height: convertToVw(45px);
            // max-width: convertToVw(50px);
            // max-height: convertToVw(50px);
            margin-right: convertToVw(20px);
            @include mq_min_750 {
              width: 45px;
              height: 45px;
              // max-width: 50px;
              // max-height: 50px;
              margin-right: 20px;
            }
            img {
              object-fit: contain;
            }
          }
          .menu-item-text {
            // @include set_font(convertToVw(25px), 400, 1);
            color: #fff;
            // @include mq_min_750 {
            //   @include set_font(25px, 400, 1);
            // }
            &.share-text {
              margin-top: convertToVw(10px);
              @include mq_min_750 {
                margin-top: 10px;
              }
            }
          }
          .notification {
            width: calc(100% - convertToVw(50px));
            @include flex(space-between, center);
            margin-right: convertToVw(10px);
            @include mq_min_750 {
              width: calc(100% - 50px);
              margin-right: 10px;
            }
            .menu-item-text {
              //   @include set_font(convertToVw(25px), 400, 1);
              color: #fff;
              //   @include mq_min_750 {
              //     @include set_font(25px, 400, 1);
              //   }
            }
            .notification-number {
              width: convertToVw(40px);
              height: convertToVw(30px);
              @include flex(center, center);
              padding: convertToVw(5px) convertToVw(10px);
              // position: absolute;
              // top: 50%;
              // right: 10px;
              // transform: translateY(-50%);
              border-radius: 40px;
              background-color: #db5656;
              color: #fff;
              //   @include set_font(convertToVw(20px), 400, 1);
              @include mq_min_750 {
                width: 40px;
                height: 30px;
                padding: 5px 10px;
                // @include set_font(20px, 400, 1);
              }
            }
          }
        }
      }
    }
  }
  .banner {
    margin-top: convertToVw(90px);
    @include mq_min_750 {
      margin-top: 90px;
    }
    .banner-list {
      .banner-item {
        max-height: convertToVw(600px);
        @include mq_min_750 {
          max-height: 600px;
        }
        img {
          object-fit: contain;
        }
      }
    }
  }
  .service {
    position: relative;
    padding: convertToVw(40px) convertToVw(30px);
    background-color: #d7dbe2;
    @include mq_min_750 {
      padding: 40px 30px;
    }
    .service-list {
      @include flex(flex-start, normal);
      flex-wrap: wrap;
      margin: 0 convertToVw(-15px);
      @include mq_min_750 {
        margin: 0 -15px;
      }
      .service-item {
        cursor: pointer;
        width: calc(50% - convertToVw(30px));
        // aspect-ratio: 1;
        @include flex(center, center);
        flex-direction: column;
        margin: 0 convertToVw(15px) convertToVw(30px);
        padding: convertToVw(30px);
        border-radius: 4px;
        background-color: #fff;
        transition: all 0.3s ease;
        @include mq_min_750 {
          width: calc(50% - 30px);
          margin: 0 15px 30px;
          padding: 30px;
        }
        &:hover {
          color: #1a75bb;
        }
        .service-item-icon {
          width: convertToVw(100px);
          height: convertToVw(100px);
          margin: 0 auto convertToVw(45px);
          // padding: convertToVw(30px);
          border-radius: 50%;
          // background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
          @include mq_min_750 {
            width: 100px;
            height: 100px;
            margin: 0 auto 45px;
            // padding: 30px;
          }
          img {
            object-fit: contain;
            width: 100%;
            height: 100%;
            // filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(1%) hue-rotate(341deg) brightness(101%)
            //   contrast(103%);
          }
        }
        .service-item-text {
          display: block;
          text-align: center;
          //   @include set_font(convertToVw(28px), 400, 1);
          line-height: 1.1;
          @include truncate_text(2);
          //   @include mq_min_750 {
          //     @include set_font(18px, 400, 1);
          //   }
        }
      }
    }
  }
}
// .overlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   min-height: 100vh;
//   background-color: rgba(0, 0, 0, 0.5);
//   z-index: 2;
//   opacity: 0;
//   visibility: hidden;
//   transition: all 0.3s ease;
//   &.active {
//     opacity: 1;
//     visibility: visible;
//   }
// }
