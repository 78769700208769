.wrapper.select-payment {
  //   background-color: #d7dbe2;
  .select-payment {
    .select-payment-method {
      .select-payment-method-item {
        margin-bottom: convertToVw(35px);
        @include mq_min_750 {
          // margin: 0 auto 35px;
          margin-bottom: 35px;
        }
        .payment-method-group {
          width: 100%;
          .payment-method-checkbox {
            width: 100%;
            margin-bottom: convertToVw(30px);
            margin-right: 0;
            padding: convertToVw(20px);
            border-radius: 6px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            background-color: #fff;
            transition: all 0.3s ease;
            @include mq_min_750 {
              margin-bottom: 30px;
              padding: 20px;
            }
            &.ant-radio-wrapper-checked {
              background-color: #1677ff;
              color: #fff;
              .ant-radio-inner {
                background-color: #fff;
                &:after {
                  background-color: #1677ff;
                }
              }
            }
            span:not(.ant-radio) {
              @include set_font(16px, 200, 1.2);
              @include mq_min_750 {
                @include set_font(23px, 200, 1);
              }
            }
          }
        }
      }
      .booking-button-wrapper {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .booking {
    width: 100%;
    position: fixed;
    bottom: 0;
    min-height: convertToVw(75px);
    padding: convertToVw(30px);
    border-top: 1px solid #fff;
    background-color: #d7dbe2;
    @include mq_min_750 {
      max-width: 1000px;
      min-height: 75px;
      padding: 30px;
    }
    .booking-button {
      display: block;
      text-align: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}
