.wrapper.calendar-detail-partner {
  .calendar-detail {
    min-height: 100vh;
    padding: convertToVw(90px) 0 convertToVw(30px);
    background-color: #d7dbe2;
    @include mq_min_750 {
      padding: 90px 0 30px;
    }
    .booking-info {
      margin: convertToVw(20px) 0;
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin: 20px 0;
        padding: 25px 30px;
      }
      .booking-info-title {
        margin-bottom: convertToVw(20px);
        // @include set_font(convertToVw(35px), 600, 1);
        font-weight: 300;
        color: #1b75ba;
        @include mq_min_750 {
          margin-bottom: 20px;
          //   @include set_font(35px, 600, 1);
        }
      }
      .booking-info-detail {
        width: 100%;
        .booking-info-key,
        .booking-info-value {
          padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
          //   @include set_font(convertToVw(35px), 400, 1);
          @include mq_min_750 {
            padding: 15px 10px 15px 0;
            // @include set_font(30px, 400, 1);
          }
        }
      }
    }
    .user-info {
      margin-bottom: convertToVw(20px);
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 25px 30px;
      }
      .user-info-name,
      .user-info-phone {
        margin-bottom: convertToVw(30px);
        // @include set_font(convertToVw(35px), 400, 1);
        @include mq_min_750 {
          margin-bottom: 30px;
          //   @include set_font(30px, 400, 1);
        }
        span {
          display: block;
        }
        .title {
          margin-bottom: convertToVw(15px);
          font-weight: 300;
          @include mq_min_750 {
            margin-bottom: 15px;
          }
        }
      }
      .user-info-phone {
        .content {
          color: #1b75ba;
        }
      }
      .user-info-detail {
        padding: convertToVw(10px) convertToVw(25px);
        border-radius: 30px;
        transition: all 0.3s ease;
        vertical-align: middle;
        &:hover {
          border: 1px solid #1b75ba;
          background: transparent;
          color: #1b75ba;
        }
        @include mq_min_750 {
          padding: 10px 25px;
        }
      }
    }
    .payment-info {
      margin-bottom: convertToVw(80px);
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 80px;
        padding: 25px 30px;
      }
      .payment-info-detail {
        width: 100%;
        .payment-info-key,
        .payment-info-value {
          padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
          //   @include set_font(convertToVw(35px), 400, 1);
          @include mq_min_750 {
            padding: 15px 10px 15px 0;
            // @include set_font(30px, 400, 1);
          }
        }
        .payment-info-key {
          max-width: 50%;
          font-weight: 300;
        }
        .money {
          font-weight: 300;
        }
        .price {
          //   @include set_font(convertToVw(40px), 600, 1);
          font-weight: 300;
          color: #da1f26;
          @include mq_min_750 {
            // @include set_font(40px, 600, 1);
          }
        }
      }
    }
  }
}
