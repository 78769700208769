.wrapper.my-calendar-partner {
  position: relative;
  .calendar {
    background-color: #fff;
    .calendar-tabs {
      .ant-tabs-nav {
        margin-bottom: 0;
        .ant-tabs-nav-wrap {
          .ant-tabs-nav-list {
            width: 100%;
            .ant-tabs-tab {
              width: 50%;
              padding: convertToVw(20px) 0;
              justify-content: center;
              text-transform: capitalize;
              @include set_font(16px, 200, 1);
              @include mq_min_750 {
                padding: 15px 0;
                @include set_font(23px, 200, 1);
              }
              .ant-tabs-tab-btn {
                font-size: inherit;
              }
            }
            .ant-tabs-ink-bar {
              width: 50% !important;
              left: 0 !important;
              transform: translateX(0) !important;
              &.right {
                left: 50% !important;
              }
            }
          }
        }
        .ant-tabs-nav-operations {
          display: none;
        }
      }
      .calendar-list {
        // min-height: 100vh;
        // margin-top: convertToVw(20px);
        padding-bottom: convertToVw(50px);
        @include mq_min_750 {
          //   margin-top: 20px;
          padding-bottom: 50px;
          // padding: 50px 30px;
        }
        .calendar-item {
          // padding: convertToVw(20px) 0;
          padding: convertToVw(30px);
          border-bottom: 1px solid #747e91;
          @include mq_min_750 {
            // padding: 20px 0;
            padding: 30px;
          }
          .calendar-item-info {
            @include flex(flex-start, flex-start);
            .calendar-icon {
              width: convertToVw(50px);
              height: convertToVw(50px);
              margin-right: convertToVw(20px);
              @include mq_min_750 {
                width: 50px;
                height: 50px;
                margin-right: 20px;
              }
              &.calendar-schedule {
                filter: brightness(0) saturate(100%) invert(44%) sepia(98%) saturate(502%) hue-rotate(315deg)
                  brightness(98%) contrast(92%);
              }
              &.calendar-complete {
                filter: brightness(0) saturate(100%) invert(64%) sepia(93%) saturate(271%) hue-rotate(164deg)
                  brightness(91%) contrast(90%);
              }
              img {
                object-fit: contain;
              }
            }
            .calendar-info {
              width: calc(100% - convertToVw(70px));
              @include mq_min_750 {
                width: calc(100% - 70px);
              }
              &:hover {
                color: #000;
              }
              .calendar-detail {
                &:hover {
                  color: initial;
                }
                .calendar-date {
                  display: block;
                  margin-bottom: convertToVw(10px);
                  //   @include set_font(convertToVw(25px), 600, 1);
                  font-weight: 300;
                  color: #1a75bb;
                  @include mq_min_750 {
                    margin-bottom: 10px;
                    // @include set_font(25px, 600, 1);
                  }
                }
                .calendar-table {
                  margin-bottom: convertToVw(40px);
                  @include mq_min_750 {
                    margin-bottom: 40px;
                  }
                  .calendar-key,
                  .calendar-value {
                    padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
                    //   @include set_font(convertToVw(35px), 400, 1.4);
                    line-height: 1.2;
                    vertical-align: top;
                    @include mq_min_750 {
                      padding: 15px 10px 15px 0;
                      // @include set_font(35px, 400, 1.4);
                    }
                  }
                  .calendar-key {
                    width: 30%;
                    font-weight: 300;
                  }
                  .calendar-value {
                    min-width: 60%;
                    padding-left: convertToVw(15px);
                    @include mq_min_750 {
                      padding-left: 15px;
                    }
                    &.calendar-price {
                      color: #d42727;
                    }
                  }
                }
              }
            }
          }
          .calendar-item-action {
            margin-left: convertToVw(70px);
            @include mq_min_750 {
              margin-left: 70px;
            }
            .calendar-contact,
            .calendar-evaluate {
              margin-right: convertToVw(20px);
              padding: convertToVw(10px) convertToVw(25px);
              border: 1px solid transparent;
              border-radius: 50px;
              transition: all 0.3s ease;
              //   @include set_font(convertToVw(35px), 400, 1);
              @include mq_min_750 {
                margin-right: 20px;
                padding: 10px 25px;
                // @include set_font(30px, 400, 1);
              }
              &:hover {
                border: 1px solid #1a75bb;
                background: transparent;
                color: #1a75bb;
              }
            }
            .calendar-confirm {
              padding: convertToVw(10px) convertToVw(25px);
              border: 1px solid transparent;
              border-radius: 50px;
              //   @include set_font(convertToVw(35px), 400, 1);
              background: linear-gradient(to right, #d52b25, #e35a14) border-box;
              @include mq_min_750 {
                padding: 10px 25px;
                // @include set_font(30px, 400, 1);
              }
              &:hover {
                border: 1px solid #d52b25;
                background: transparent;
                color: #d52b25;
              }
              &.complete {
                background: linear-gradient(to right, #21a1ba, #49c87f) border-box;
                &:hover {
                  border: 1px solid #21a1ba;
                  background: transparent;
                  color: #21a1ba;
                }
              }
            }
          }
          .user-info {
            width: 70%;
            max-height: 80%;
            // height: 50%;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            visibility: hidden;
            padding: convertToVw(50px) convertToVw(50px) convertToVw(100px);
            border-radius: 10px;
            background-color: #fff;
            z-index: 2;
            transition: all 0.3s ease;
            overflow: auto;
            @include mq_min_750 {
              width: 50%;
              max-width: 680px;
              padding: 50px 50px 100px;
            }
            &.active {
              opacity: 1;
              visibility: visible;
            }
            .user-close {
              display: block;
              margin-left: auto;
              padding: convertToVw(15px);
              //   @include set_font(convertToVw(30px), 400, 1);
              @include mq_min_750 {
                padding: 15px;
                // @include set_font(30px, 400, 1);
              }
            }
            .user-avt {
              width: convertToVw(150px);
              height: convertToVw(150px);
              margin: 0 auto convertToVw(20px);
              border-radius: 50%;
              @include mq_min_750 {
                width: 150px;
                height: 150px;
                margin: 0 auto 20px;
              }
            }
            .user-name {
              display: block;
              margin-bottom: convertToVw(50px);
              text-align: center;
              //   @include set_font(convertToVw(35px), 600, 1);
              font-weight: 300;
              color: #1a75bb;
              @include mq_min_750 {
                margin-bottom: 50px;
                // @include set_font(35px, 600, 1);
              }
            }
            .user-speciality {
              display: block;
              margin-bottom: convertToVw(30px);
              text-align: center;
              //   @include set_font(convertToVw(30px), 400, 1);
              @include mq_min_750 {
                margin-bottom: 30px;
                // @include set_font(30px, 400, 1);
              }
            }
            .user-phone {
              @include flex(center, center);
              padding: convertToVw(15px) convertToVw(20px);
              border-radius: 8px;
              //   @include set_font(convertToVw(35px), 400, 1);
              background: linear-gradient(to right, #21a1ba, #49c87f) border-box;
              color: #fff;
              @include mq_min_750 {
                padding: 15px 20px;
                // @include set_font(30px, 400, 1);
              }
              .user-phone-icon {
                width: convertToVw(35px);
                height: convertToVw(35px);
                margin-right: convertToVw(15px);
                filter: brightness(0) saturate(100%) invert(100%) sepia(68%) saturate(2%) hue-rotate(57deg)
                  brightness(102%) contrast(101%);
                @include mq_min_750 {
                  width: 35px;
                  height: 35px;
                  margin-right: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}
.user-evaluate {
  width: 100% !important;
  max-width: 70% !important;
  @include mq_min_750 {
    max-width: 500px !important;
  }
  //   max-width: 50%;
  //   width: auto !important;
  //   margin: 0 auto;
  //   .ant-modal-content {
  //     padding-bottom: convertToVw(40px);
  //     @include mq_min_750 {
  //       padding-bottom: 40px;
  //     }
  //   }
  .user-evaluate-body {
    // padding: convertToVw(20px) convertToVw(30px) convertToVw(50px);
    // @include mq_min_750 {
    //   padding: 20px 30px 50px;
    // }
    .user-info {
      margin-bottom: convertToVw(20px);
      @include mq_min_750 {
        margin-bottom: 20px;
      }
      .user-avatar {
        width: convertToVw(150px);
        height: convertToVw(150px);
        margin: 0 auto convertToVw(20px);
        border-radius: 50%;
        @include mq_min_750 {
          width: 150px;
          height: 150px;
          margin: 0 auto 20px;
        }
      }
      .user-name {
        display: block;
        margin-bottom: convertToVw(10px);
        text-align: center;
        // @include set_font(convertToVw(25px), 600, 1);
        font-weight: 300;
        color: #1a75bb;
        @include mq_min_750 {
          margin-bottom: 10px;
          //   @include set_font(25px, 600, 1);
        }
      }
      .user-description {
        display: inline-block;
        // margin-bottom: convertToVw(30px);
        text-align: center;
        // @include set_font(convertToVw(25px), 400, 1.1);
        @include mq_min_750 {
          //   margin-bottom: 30px;
          //   @include set_font(25px, 400, 1.1);
        }
      }
    }
    .user-evaluate-rate {
      @include flex(center, center);
      margin-bottom: convertToVw(30px);
      @include mq_min_750 {
        margin-bottom: 30px;
      }
      //   .ant-rate-star {
      // @include set_font(convertToVw(25px), 400, 1);
      // @include mq_min_750 {
      //   @include set_font(25px, 400, 1);
      // }
      //   }
      //   .star-item {
      //     width: convertToVw(70px);
      //     height: convertToVw(70px);
      //     margin-right: convertToVw(5px);
      //     @include mq_min_750 {
      //       width: 70px;
      //       height: 70px;
      //       margin-right: 5px;
      //     }
      //     &:last-child {
      //       margin-right: 0;
      //     }
      //   }
    }
    .user-evaluate-review {
      margin-bottom: convertToVw(20px);
      padding: convertToVw(20px);
      border-radius: 5px;
      //   @include set_font(convertToVw(25px), 400, 1);
      resize: none;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 20px;
        // @include set_font(25px, 400, 1);
      }
    }
    .user-evaluate-submit {
      width: 100%;
      height: auto;
      //   border: 0;
      padding: convertToVw(15px) convertToVw(50px);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
      //   @include set_font(convertToVw(25px), 400, 1);
      @include mq_min_750 {
        padding: 15px 50px;
        // @include set_font(25px, 400, 1);
      }
      &:hover {
        color: #fff;
        background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
        // border: 0;
      }
    }
  }
}
