.wrapper.new-shift-notification {
  .header {
    .header-back-button {
      @include flex(flex-start, center);
      .header-back-text {
        margin-left: convertToVw(25px);
        @include set_font(convertToVw(35px), 300, 1);
        color: #fff;
        @include mq_min_750 {
          margin-left: 25px;
          @include set_font(35px, 300, 1);
        }
      }
    }
  }
  .new-shift-notification {
    background-color: #d7dbe2;
    .list-task {
      .task-item {
        @include flex(flex-start, center);
        margin-bottom: convertToVw(20px);
        padding: convertToVw(30px);
        border-radius: 8px;
        background-color: #fff;
        @include mq_min_750 {
          margin-bottom: 20px;
          padding: 30px;
        }
        &:last-child {
          margin-bottom: 0;
        }
        .task-item-image {
          width: convertToVw(150px);
          height: convertToVw(150px);
          margin-right: convertToVw(20px);
          overflow: hidden;
          @include mq_min_750 {
            width: 150px;
            height: 150px;
            margin-right: 20px;
          }
        }
        .task-item-name {
          width: calc(100% - convertToVw(170px));
          @include set_font(convertToVw(35px), 300, 1);
          @include truncate_text(2);
          color: #1b75ba;
          @include mq_min_750 {
            width: calc(100% - 170px);
            @include set_font(35px, 300, 1);
          }
        }
      }
    }
  }
}
