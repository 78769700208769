.wrapper.calendar-detail {
  background-color: #d7dbe2;
  .calendar-detail {
    // min-height: 100vh;
    // background-color: #d7dbe2;
    .booking-info {
      margin: convertToVw(20px) 0;
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin: 20px 0;
        padding: 25px 30px;
      }
      .booking-info-title {
        margin-bottom: convertToVw(20px);
        font-weight: 300;
        color: #1b75ba;
        @include mq_min_750 {
          margin-bottom: 20px;
        }
      }
      .booking-info-detail {
        width: 100%;
        .booking-info-key,
        .booking-info-value {
          padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
          @include mq_min_750 {
            padding: 15px 10px 15px 0;
          }
        }
      }
    }
    .partner-info {
      margin-bottom: convertToVw(20px);
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 25px 30px;
      }
      .partner-info-name,
      .partner-info-phone {
        margin-bottom: convertToVw(30px);
        @include mq_min_750 {
          margin-bottom: 30px;
        }
        span {
          display: block;
        }
        .title {
          margin-bottom: convertToVw(15px);
          font-weight: 300;
          @include mq_min_750 {
            margin-bottom: 15px;
          }
        }
      }
      .partner-info-phone {
        .content {
          color: #1b75ba;
        }
      }
      .partner-info-detail {
        padding: convertToVw(10px) convertToVw(25px);
        border-radius: 30px;
        transition: all 0.3s ease;
        vertical-align: middle;
        &:hover {
          border: 1px solid #1b75ba;
          background: transparent;
          color: #1b75ba;
        }
        @include mq_min_750 {
          padding: 10px 25px;
        }
      }
    }
    .payment-info {
      margin-bottom: convertToVw(80px);
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 80px;
        padding: 25px 30px;
      }
      .payment-info-detail {
        width: 100%;
        .payment-info-key,
        .payment-info-value {
          padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
          @include mq_min_750 {
            padding: 15px 10px 15px 0;
          }
        }
        .payment-info-key {
          max-width: 50%;
          font-weight: 300;
        }
        .money {
          font-weight: 300;
        }
        .price {
          font-weight: 300;
          color: #da1f26;
        }
      }
    }
  }
}
.partner-info-modal {
  .partner-info-modal-item-image {
    width: convertToVw(150px);
    height: convertToVw(150px);
    margin: 0 auto convertToVw(20px);
    border-radius: 50%;
    overflow: hidden;
    @include mq_min_750 {
      width: 150px;
      height: 150px;
      margin-bottom: 20px;
    }
  }
  .partner-info-modal-item {
    @include flex(space-between, normal);
    padding: convertToVw(10px);
    background-color: #f5f5f5;
    border-radius: 5px;
    @include set_font(convertToVw(25px), 400, 1);
    @include mq_min_750 {
      padding: 10px;
      @include set_font(25px, 400, 1);
    }
    &:not(:last-child) {
      margin-bottom: convertToVw(10px);
      @include mq_min_750 {
        margin-bottom: 10px;
      }
    }
    .partner-info-modal-item-title {
      font-weight: 300;
    }
    .partner-info-modal-item-content {
      &.rate {
        @include flex(flex-start, center);
        .text {
          display: inline-block;
          margin-right: convertToVw(10px);
          @include mq_min_750 {
            margin-right: 10px;
          }
        }
        .star {
          @include set_font(convertToVw(20px), 400, 1);
          color: #f1c40f;
          @include mq_min_750 {
            @include set_font(20px, 400, 1);
          }
        }
      }
    }
    &.document {
      display: block;
      .document-list {
        @include flex(space-between, normal);
        flex-wrap: wrap;
        gap: convertToVw(10px);
        margin-top: convertToVw(10px);
        @include mq_min_750 {
          gap: 10px;
          margin-top: 10px;
        }
        .document-item {
          width: calc(50% - convertToVw(10px));
          padding: convertToVw(10px);
          border: 1px solid #fff;
          border-radius: 5px;
          @include mq_min_750 {
            width: calc(50% - 10px);
            padding: 10px;
          }
        }
      }
    }
  }
}
.document-image-preview {
  .ant-image-preview-img-wrapper {
    padding: convertToVw(50px);
    @include mq_min_750 {
      padding: 50px;
    }
    img {
      height: auto;
      object-fit: contain;
    }
  }
}
