.wrapper.transaction-statistic {
  background-color: #d7dbe2;
  .transaction-statistic {
    padding: convertToVw(90px) 0 convertToVw(50px);
    @include mq_min_750 {
      padding: 90px 0 50px;
    }
    .transaction-statistic-title {
      margin: convertToVw(50px) 0 0;
      padding: convertToVw(15px) convertToVw(30px);
      border-bottom: 1px solid #efefef;
      //   @include set_font(convertToVw(35px), 600, 1);
      text-transform: uppercase;
      font-weight: 300;
      background-color: #fff;
      @include mq_min_750 {
        margin: 50px 0 0;
        padding: 15px 30px;
        // @include set_font(35px, 600, 1);
      }
    }
    .transaction-statistic-item {
      @include flex(flex-start, center);
      margin-bottom: convertToVw(20px);
      padding: convertToVw(30px) convertToVw(30px) 0;
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 30px 30px 0;
      }
      .transaction-statistic-info {
        width: 50%;
        padding: convertToVw(30px) 0;
        text-align: center;
        @include mq_min_750 {
          padding: 30px 0;
        }
        &:first-child {
          border-right: 1px solid #efefef;
        }
        .transaction-statistic-text {
          margin-bottom: convertToVw(15px);
          //   @include set_font(convertToVw(40px), 400, 1);
          text-transform: uppercase;
          @include mq_min_750 {
            margin-bottom: 15px;
            // @include set_font(40px, 400, 1);
          }
        }
        .transaction-statistic-number {
          font-weight: 300;
        }
      }
    }
  }
}
