.wrapper.create-password {
  .create-password {
    .create-password-form {
      .create-password-item {
        margin-bottom: convertToVw(30px) !important;
        @include mq_min_750 {
          margin-bottom: 30px !important;
        }
        .create-password-label {
          @include flex(flex-start, center);
          .create-password-label-icon {
            width: convertToVw(30px);
            height: convertToVw(30px);
            margin-right: convertToVw(10px);
            filter: brightness(0) saturate(100%) invert(37%) sepia(64%) saturate(977%) hue-rotate(172deg)
              brightness(90%) contrast(95%);
            @include mq_min_750 {
              width: 23px;
              height: 23px;
              margin-right: 10px;
            }
            img {
              object-fit: contain;
            }
          }
        }
        .create-password-input {
          padding: convertToVw(15px) convertToVw(10px);
          border-radius: 4px;
          @include set_font(16px, 200, 1);
          @include mq_min_750 {
            padding: 15px 10px;
            @include set_font(23px, 200, 1);
          }
        }
      }
      .create-password-button {
        // display: block;
        width: 100%;
        margin-top: convertToVw(30px);
        padding: convertToVw(25px) convertToVw(30px);
        @include set_font(16px, 200, 1);
        background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
        @include mq_min_750 {
          margin-top: 30px;
          padding: 15px 30px;
          @include set_font(23px, 200, 1);
        }
      }
    }
  }
}
.form-createpass-item {
  font-size: 16px;
  display: flex;
  align-items: center;
  margin: 20px 0 10px;
}

.icon-createpass {
  width: convertToVw(35px);
  height: convertToVw(35px);
  margin-right: convertToVw(20px);
  filter: brightness(0) saturate(100%) invert(37%) sepia(64%) saturate(977%) hue-rotate(172deg) brightness(90%)
    contrast(95%);
  @include mq_min_750 {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
  img {
    object-fit: contain;
  }
}

.btn-createpass {
  margin-top: 20px;
  width: 100%;
  height: 40px;
  font-size: 16px;
  background: #1890ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  &:hover {
    background: #40a9ff;
  }
}

.text-createpass {
  color: #1890ff;
  align-content: center;
  font-size: 20px;
  @include mq_min_750 {
    font-size: 26px;
  }
  &--title {
    color: #fff;
    align-content: center;
    font-size: 20px;
    @include mq_min_750 {
      font-size: 26px;
    }
  }
}
