.wrapper.admin-notification {
  .admin-notification {
    .admin-notification-title {
      margin-bottom: convertToVw(30px);
      @include set_font(convertToVw(40px), 300, 1);
      @include mq_min_750 {
        margin-bottom: 30px;
        @include set_font(40px, 300, 1);
      }
    }
    .admin-notification-image {
      max-height: convertToVw(450px);
      margin-bottom: convertToVw(30px);
      border-radius: 30px;
      overflow: hidden;
      @include mq_min_750 {
        max-height: 450px;
        margin-bottom: 30px;
      }
    }
    .admin-notification-description {
      @include set_font(convertToVw(35px), 400, 1.3);
      @include mq_min_750 {
        @include set_font(35px, 400, 1.3);
      }
    }
  }
}
