.wrapper.select-relative {
  .relative {
    .relative-list {
      background-color: #fff;
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #81bff2;
        border-radius: 15px;
      }
      &::-webkit-scrollbar {
        width: convertToVw(10px);
        background-color: transparent;
        @include mq_min_750 {
          width: 10px;
        }
      }
      // @include mq_min_750 {
      // 	padding: 20px;
      // }
      .relative-add {
        margin-bottom: convertToVw(30px);
        @include mq_min_750 {
          margin-bottom: 30px;
        }
        .relative-add-button {
          margin: 0 auto;
          max-width: convertToVw(300px);
          @include flex(center, center);
          padding: convertToVw(15px) convertToVw(25px);
          border: 1px solid #1a75bb;
          border-radius: 30px;
          cursor: pointer;
          color: #1a75bb;
          @include mq_min_750 {
            max-width: 250px;
            padding: 15px 25px;
          }
          .relative-add-icon {
            width: convertToVw(25px);
            height: convertToVw(25px);
            margin-right: convertToVw(20px);
            @include mq_min_750 {
              width: 23px;
              height: 23px;
              margin-right: 20px;
            }
          }
        }
      }
      .relative-item {
        @include flex(space-between, center);
        // margin-bottom: convertToVw(20px);
        padding: convertToVw(15px) 0;
        border-bottom: 1px solid #9fa5ad;
        &:nth-child(2) {
          border-top: 1px solid #9fa5ad;
        }
        cursor: pointer;
        @include mq_min_750 {
          // margin-bottom: 20px;
          padding: 15px 0;
        }
        .relative-item-wrapper {
          @include flex(flex-start, center);
          .relative-item-avt {
            width: convertToVw(50px);
            height: convertToVw(50px);
            border-radius: 50%;
            margin-right: convertToVw(20px);
            overflow: hidden;
            background-color: #a5a5a5;
            @include mq_min_750 {
              width: 50px;
              height: 50px;
              margin-right: 20px;
            }
          }
          .relative-item-info {
            .relative-item-name {
              @include set_font(16px, 300, 1);
              color: #1a75bb;
              @include mq_min_750 {
                @include set_font(25px, 300, 1);
              }
            }
            .relative-item-relationship {
              @include set_font(16px, 200, 1);
              @include mq_min_750 {
                @include set_font(30px, 200, 1);
              }
            }
          }
        }
      }
    }
    .amount {
      margin-top: convertToVw(140px);
      @include mq_min_750 {
        margin-top: 140px;
      }
      .amount-item {
        @include flex(space-between, center);
        margin-bottom: convertToVw(20px);
        @include set_font(convertToVw(35px), 400, 1);
        @include mq_min_750 {
          margin-bottom: 20px;
          @include set_font(25px, 400, 1);
        }
        &:nth-child(3) {
          .amount-text {
            color: #1a75bb;
          }
          .amount-value {
            @include set_font(convertToVw(60px), 400, 1);
            color: #d42727;
            @include mq_min_750 {
              @include set_font(60px, 400, 1);
            }
          }
        }
      }
    }
  }
  .booking {
    width: 100%;
    position: fixed;
    bottom: 0;
    min-height: convertToVw(75px);
    padding: convertToVw(30px);
    border-top: 1px solid #fff;
    background-color: #d7dbe2;
    @include mq_min_750 {
      max-width: 1000px;
      min-height: 75px;
      padding: 30px;
    }
    .booking-button {
      display: block;
      text-align: center;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
}
