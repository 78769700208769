.wrapper.change-language {
  .language-list {
    .language-item {
      @include flex(space-between, center);
      padding: convertToVw(15px) convertToVw(10px);
      border-bottom: 1px solid #747e91;
      @include set_font(convertToVw(25px), 500, 1);
      cursor: pointer;
      @include mq_min_750 {
        padding: 15px 10px;
        @include set_font(25px, 500, 1);
      }
      .language-item-check {
        width: auto;
      }
    }
  }
}
