.wrapper.wallet {
  .header {
    border-bottom: 1px solid #e5e5e5;
  }
  .wallet {
    background-color: #d7dbe2;
    .wallet-info {
      margin-bottom: convertToVw(30px);
      padding: convertToVw(60px) 0;
      background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
      @include mq_min_750 {
        margin-bottom: 30px;
        padding: 60px 0;
      }
      .wallet-info-icon {
        width: convertToVw(100px);
        height: convertToVw(100px);
        margin: 0 auto convertToVw(40px);
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(21deg) brightness(104%)
          contrast(103%);
        @include mq_min_750 {
          width: 100px;
          height: 100px;
          margin: 0 auto 40px;
        }
      }
      .wallet-info-text {
        @include set_font(20px, 300, 1);
        text-align: center;
        color: #fff;
        @include mq_min_750 {
          @include set_font(30px, 300, 1);
        }
      }
    }
    .wallet-setting {
      background-color: #fff;
      .wallet-setting-item {
        display: block;
        padding: convertToVw(15px) convertToVw(30px);
        border-bottom: 1px solid #e5e5e5;
        transition: all 0.3s ease;
        @include mq_min_750 {
          padding: 15px 30px;
        }
        &:hover {
          color: #1a75bb;
        }
      }
    }
  }
}
