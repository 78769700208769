.wrapper.task-info-partner {
  background-color: #d7dbe2;
  .task-info {
    .booking-info {
      margin: convertToVw(20px) 0;
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin: 20px 0;
        padding: 25px 30px;
      }
      .booking-info-title {
        margin-bottom: convertToVw(20px);
        font-weight: 300;
        color: #1b75ba;
        @include mq_min_750 {
          margin-bottom: 20px;
        }
      }
      .booking-info-detail {
        width: 100%;
        .booking-info-key,
        .booking-info-value {
          padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
          @include mq_min_750 {
            padding: 15px 10px 15px 0;
          }
        }
      }
    }
    .user-info {
      margin-bottom: convertToVw(20px);
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-bottom: 20px;
        padding: 25px 30px;
      }
      .user-info-name,
      .user-info-address,
      .user-info-note {
        margin-bottom: convertToVw(30px);
        @include mq_min_750 {
          margin-bottom: 30px;
        }
        span {
          display: block;
        }
        .title {
          margin-bottom: convertToVw(15px);
          font-weight: 300;
          @include mq_min_750 {
            margin-bottom: 15px;
          }
        }
        .content {
          line-height: 1.2;
        }
      }
      .user-info-note {
        margin-bottom: 0;
        .content {
          color: #1e80ff;
        }
      }
    }
    .payment-info {
      padding: convertToVw(25px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        padding: 25px 30px;
      }
      .payment-info-detail {
        width: 100%;
        .payment-info-key,
        .payment-info-value {
          padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
          @include mq_min_750 {
            padding: 15px 10px 15px 0;
          }
        }
        .payment-info-key {
          max-width: 50%;
          font-weight: 300;
        }
        .money {
          font-weight: 300;
        }
        .price {
          font-weight: 300;
          color: #da1f26;
        }
      }
    }
    .list-task {
      margin-top: convertToVw(20px);
      padding: convertToVw(50px) convertToVw(30px);
      background-color: #fff;
      @include mq_min_750 {
        margin-top: 20px;
        padding: 50px 30px;
      }
      .list-task-title {
        @include flex(space-between, center);
        margin-bottom: convertToVw(20px);
        color: #1b75ba;
        @include mq_min_750 {
          margin-bottom: 20px;
        }
      }
      .list-task-table {
        overflow-x: auto;
        border-radius: 8px;
        .ant-table-wrapper {
          .ant-table {
            .ant-table-header {
              .ant-table-thead {
                .ant-table-cell {
                  font-weight: 300;
                }
              }
            }
          }
        }
      }
    }
    .task-confirm-button {
      padding: convertToVw(20px);
      @include mq_min_750 {
        padding: 20px;
      }
      .confirm-button {
        width: 100%;
        margin-top: convertToVw(100px);
        transition: all 0.3s ease;
        // @include set_font(convertToVw(35px), 400, 1);
        @include mq_min_750 {
          margin-top: 100px;
          //   @include set_font(30px, 400, 1);
        }
        &:hover {
          border: 1px solid transparent;
          background: linear-gradient(to right, #4f5bb4, #359dd4) border-box;
          color: #fff;
        }
        &:disabled {
          cursor: not-allowed;
          border-color: #d9d9d9;
          color: rgba(0, 0, 0, 0.25);
          background: rgba(0, 0, 0, 0.04);
          box-shadow: none;
        }
        .custom-spin .ant-spin-dot-item {
          background-color: white !important;
        }
      }
    }
  }
}
.ant-modal.take-job-success {
  top: 50%;
  transform: translateY(-50%);
  .ant-modal-content {
    .ant-modal-body {
      .ant-modal-confirm-body-wrapper {
        font-family: "MyriadPro-Light", sans-serif;
        .success-modal {
          margin-left: -34px;
          //   width: 70%;
          //   max-height: 80%;
          //   position: fixed;
          //   top: 50%;
          //   left: 50%;
          //   transform: translate(-50%, -50%);
          //   opacity: 0;
          //   visibility: hidden;
          //   padding: convertToVw(50px) convertToVw(50px);
          //   border-radius: 10px;
          //   background-color: #fff;
          //   z-index: 3;
          //   transition: all 0.3s ease;
          //   overflow: auto;
          //   @include mq_min_750 {
          //     width: 50%;
          //     max-width: 680px;
          //     padding: 50px 50px;
          //   }
          //   &.active {
          //     opacity: 1;
          //     visibility: visible;
          //   }
          .success-modal-close {
            display: block;
            margin-left: auto;
            padding: convertToVw(15px);
            // @include set_font(convertToVw(30px), 400, 1);
            @include mq_min_750 {
              padding: 15px;
              //   @include set_font(30px, 400, 1);
            }
          }
          .success-modal-title {
            margin-bottom: convertToVw(30px);
            //   @include set_font(convertToVw(30px), 600, 1);
            //   font-weight: 600;
            text-align: center;
            color: #1b75ba;
            @include mq_min_750 {
              margin-bottom: 30px;
              // @include set_font(30px, 600, 1);
            }
          }
          .success-modal-text {
            margin-bottom: convertToVw(30px);
            //   @include set_font(convertToVw(30px), 400, 1);
            text-align: center;
            @include mq_min_750 {
              margin-bottom: 30px;
              // @include set_font(30px, 400, 1);
            }
          }
          .success-modal-avt {
            width: convertToVw(150px);
            height: convertToVw(150px);
            margin: 0 auto convertToVw(20px);
            border-radius: 50%;
            @include mq_min_750 {
              width: 150px;
              height: 150px;
              margin: 0 auto 20px;
            }
          }
          .success-modal-name {
            display: block;
            margin-bottom: convertToVw(20px);
            text-align: center;
            // @include set_font(convertToVw(30px), 600, 1);
            font-weight: 300;
            color: #1a75bb;
            @include mq_min_750 {
              margin-bottom: 20px;
              //   @include set_font(30px, 600, 1);
            }
          }
          .success-modal-phone {
            @include flex(center, center);
            padding: convertToVw(15px) convertToVw(20px);
            border-radius: 8px;
            // @include set_font(convertToVw(35px), 400, 1);
            background: linear-gradient(to right, #21a1ba, #49c87f) border-box;
            color: #fff;
            @include mq_min_750 {
              padding: 15px 20px;
              //   @include set_font(30px, 400, 1);
            }
            .success-modal-phone-icon {
              width: convertToVw(20px);
              height: convertToVw(20px);
              margin-right: convertToVw(15px);
              filter: brightness(0) saturate(100%) invert(100%) sepia(68%) saturate(2%) hue-rotate(57deg)
                brightness(102%) contrast(101%);
              @include mq_min_750 {
                width: 20px;
                height: 20px;
                margin-right: 15px;
              }
            }
          }
        }
        .ant-modal-confirm-btns {
          margin-top: convertToVw(20px);
          @include mq_min_750 {
            margin-top: 20px;
          }
          .ant-btn {
            height: auto;
            padding: convertToVw(5px) convertToVw(20px);
            font-family: "MyriadPro-Light", sans-serif;
            @include mq_min_750 {
              padding: 5px 20px;
            }
          }
        }
      }
    }
  }
}
