.wrapper.task-list {
  .task-list {
    .task-item {
      @include flex(flex-start, flex-start);
      // padding: convertToVw(20px) 0;
      padding: convertToVw(25px) convertToVw(30px);
      border-bottom: 1px solid #747e91;
      @include mq_min_750 {
        // padding: 20px 0;
        padding: 25px 30px;
      }
      .task-icon {
        width: convertToVw(50px);
        height: convertToVw(50px);
        margin-right: convertToVw(20px);
        filter: brightness(0) saturate(100%) invert(67%) sepia(44%) saturate(560%) hue-rotate(170deg) brightness(100%)
          contrast(95%);
        @include mq_min_750 {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
        img {
          object-fit: contain;
        }
      }
      .task-info {
        width: calc(100% - convertToVw(70px));
        @include mq_min_750 {
          width: calc(100% - 70px);
        }
        .task-info-name {
          margin-bottom: convertToVw(15px);
          //   @include set_font(convertToVw(35px), 600, 1);
          color: #1a75bb;
          @include mq_min_750 {
            margin-bottom: 15px;
            // @include set_font(35px, 600, 1);
          }
        }
        .task-info-detail {
          margin-bottom: convertToVw(25px);
          @include mq_min_750 {
            margin-bottom: 25px;
          }
          .task-info-detail-item {
            // margin-bottom: convertToVw(15px);
            // @include mq_min_750 {
            //   margin-bottom: 15px;
            // }
            @include set_font(convertToVw(35px), 400, 1.4);
            @include mq_min_750 {
              @include set_font(35px, 400, 1.4);
            }
            // .task-info-detail-key {
            //   display: inline-block;
            //   padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) 0;
            // }
            .task-info-detail-value {
              //   display: inline-block;
              //   padding: convertToVw(15px) convertToVw(10px);
              &.price {
                color: #d42727;
              }
            }
          }
        }
        .task-info-more {
          padding: convertToVw(15px) convertToVw(50px);
          border: 1px solid #1a75bb;
          border-radius: 30px;
          //   @include set_font(convertToVw(35px), 600, 1);
          color: #1a75bb;
          @include mq_min_750 {
            padding: 15px 50px;
            // @include set_font(35px, 600, 1);
          }
        }
      }
    }
  }
  .footer-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0 auto 30px;
    padding: 0;
  }
}
