.wrapper.forgot-password {
  .forgot-password {
    .logo {
      max-width: convertToVw(150px);
      margin: 0 auto convertToVw(50px);
      @include mq_min_750 {
        max-width: 150px;
        margin: 0 auto 50px;
      }
    }
    .forgot-password-form {
      .forgot-password-form-item {
        position: relative;
        &:not(:first-child) {
          margin-top: convertToVw(40px);
          @include mq_min_750 {
            margin-top: 40px;
          }
        }
        .forgot-password-form-icon {
          width: convertToVw(35px);
          height: convertToVw(35px);
          position: absolute;
          top: 50%;
          left: convertToVw(20px);
          transform: translateY(-50%);
          filter: brightness(0) saturate(100%) invert(59%) sepia(0%) saturate(330%) hue-rotate(339deg) brightness(95%)
            contrast(93%);
          @include mq_min_750 {
            width: 35px;
            height: 35px;
            left: 20px;
          }
        }

        .forgot-password-form-input {
          padding: convertToVw(15px) convertToVw(10px) convertToVw(15px) convertToVw(70px);
          border: 1px solid #747e91;
          border-radius: 4px;
          //   @include set_font(convertToVw(25px), 500, 1);
          @include mq_min_750 {
            padding: 15px 10px 15px 70px;
            // @include set_font(25px, 500, 1);
          }
        }
        &.button {
          @include flex(space-between, normal);
          .forgot-password-form-cancelbtn {
            width: calc(50% - convertToVw(20px));
            padding: convertToVw(15px) convertToVw(60px);
            // @include set_font(convertToVw(25px), 400, 1);
            text-align: center;
            @include mq_min_750 {
              padding: 15px 60px;
              //   @include set_font(25px, 400, 1);
            }
          }
          .forgot-password-form-submitbtn {
            width: calc(50% - convertToVw(20px));
            padding: convertToVw(15px) convertToVw(60px);
            @include mq_min_750 {
              padding: 15px 60px;
            }
            // @include set_font(convertToVw(25px), 400, 1);
            // @include mq_min_750 {
            //   @include set_font(25px, 400, 1);
            // }
          }
        }
      }
    }
  }
}
