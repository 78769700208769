.wrapper.rule {
  .rule {
    .logo {
      max-width: convertToVw(300px);
      margin: 0 auto convertToVw(100px);
      @include mq_min_750 {
        max-width: 300px;
        margin: 0 auto 100px;
      }
    }
    .title {
      margin-bottom: convertToVw(30px);
      //   @include set_font(convertToVw(40px), 600, 1.2);
      color: #1b75ba;
      @include mq_min_750 {
        margin-bottom: 30px;
        // @include set_font(50px, 600, 1.2);
      }
    }
    .text {
      //   @include set_font(convertToVw(35px), 400, 1.2);
      //   @include mq_min_750 {
      //     @include set_font(35px, 400, 1.2);
      //   }
      ul {
        list-style-type: disc;
        padding-left: convertToVw(50px);
        @include mq_min_750 {
          padding-left: 50px;
        }
        li {
          margin-bottom: convertToVw(10px);
          @include mq_min_750 {
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
