.wrapper.service-info {
  background-color: #d7dbe2;
  .content {
    position: relative;
    .banner {
      max-height: convertToVw(450px);
      border-radius: 15px;
      overflow: hidden;
      @include mq_min_750 {
        max-height: 450px;
      }
    }
    .description {
      margin-top: convertToVw(40px);
      //   @include set_font(convertToVw(31px), 400, 1);
      @include mq_min_750 {
        margin-top: 40px;
        // @include set_font(23px, 400, 1);
      }
      .description-wrapper {
        &:not(:first-child) {
          margin-top: convertToVw(20px);
          @include mq_min_750 {
            margin-top: 20px;
          }
        }
        .content-title {
          margin-bottom: convertToVw(20px);
          //   @include set_font(convertToVw(25px), 600, 1);
          font-weight: 400;
          color: #1a75bb;
          @include mq_min_750 {
            margin-bottom: 20px;
            // @include set_font(23px, 600, 1);
          }
        }
        .content-text {
          max-height: calc(16px * 1.5 * 5);
          margin-top: convertToVw(20px);
          white-space: break-spaces;
          text-align: justify;
          //   @include set_font(convertToVw(23px), 400, 1.5);
          line-height: 1.5;
          @include truncate_text(5);
          transition: all 0.3s ease;
          @include mq_min_750 {
            max-height: calc(23px * 1.5 * 5);
            margin-top: 20px;
            // @include set_font(21px, 400, 1.5);
          }
          &.read-less {
            max-height: 100%;
            overflow: initial;
            line-clamp: initial;
            -webkit-line-clamp: initial;
          }
        }
        .text-action {
          display: block;
          margin-top: convertToVw(10px);
          cursor: pointer;
          color: #1a75bb;
          @include mq_min_750 {
            margin-top: 10px;
          }
        }
        .content-procedure {
          @include flex(flex-start, normal);
          margin-top: convertToVw(20px);
          padding-bottom: convertToVw(40px);
          overflow-x: auto;
          &::-webkit-scrollbar-track {
            background-color: transparent;
          }
          &::-webkit-scrollbar-thumb {
            background-color: #81bff2;
            border-radius: 10px;
          }
          &::-webkit-scrollbar {
            height: convertToVw(10px);
            background-color: transparent;
            @include mq_min_750 {
              height: 10px;
            }
          }
          @include mq_min_750 {
            margin-top: 20px;
            padding-bottom: 40px;
          }
          .procedure-step {
            .ant-steps-item {
              padding-bottom: convertToVw(20px);
              @include mq_min_750 {
                padding-bottom: 20px;
              }
              .ant-steps-item-tail {
                left: calc(convertToVw(41px) / 2);
                &::after {
                  background-color: #1677ff;
                }
                @include mq_min_750 {
                  left: calc(41px / 2);
                }
              }
              .ant-steps-item-icon {
                width: convertToVw(41px);
                height: convertToVw(41px);
                @include flex(center, center);
                //   vertical-align: middle;
                @include mq_min_750 {
                  width: 41px;
                  height: 41px;
                }
              }
              .ant-steps-item-content {
                .ant-steps-item-title {
                  margin-bottom: convertToVw(10px);
                  @include set_font(16px, 400, 1);
                  color: #1a75bb;
                  @include mq_min_750 {
                    margin-bottom: 10px;
                    @include set_font(23px, 400, 1);
                  }
                }
                .ant-steps-item-description {
                  @include set_font(16px, 200, 1.5);
                  @include mq_min_750 {
                    @include set_font(23px, 200, 1.5);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
