.has-bottom-button {
  padding-bottom: convertToVw(170px);
  @include mq_min_750 {
    padding-bottom: 170px;
  }
}
.booking-button-wrapper {
  width: 100%;
  //   min-height: convertToVw(120px);
  // @include flex(center, center);
  position: fixed;
  bottom: 0;
  padding: convertToVw(20px);
  border-top: 2px solid #efefef;
  background-color: #fff;
  @include mq_min_750 {
    max-width: 1000px;
    // min-height: 120px;
    padding: 20px;
  }
  .booking-button {
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    text-align: center;
    // @include set_font(convertToVw(23px), 400, 1);
    // @include mq_min_750 {
    //   @include set_font(21px, 400, 1);
    // }
  }
}
