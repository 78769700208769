.wrapper.change-password {
  .header {
    .header-call {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(273deg) brightness(103%)
        contrast(103%);
    }
  }
  .change-password-form {
    .form-item {
      margin-bottom: convertToVw(30px);
      @include mq_min_750 {
        margin-bottom: 30px;
      }
      .label {
        @include flex(flex-start, center);
        margin-bottom: convertToVw(20px);
        // @include set_font(convertToVw(25px), 500, 1);
        color: #1a75bb;
        @include mq_min_750 {
          margin-bottom: 20px;
          //   @include set_font(25px, 500, 1);
        }
        .icon {
          width: convertToVw(25px);
          height: convertToVw(25px);
          margin-right: convertToVw(20px);
          filter: brightness(0) saturate(100%) invert(37%) sepia(64%) saturate(977%) hue-rotate(172deg) brightness(90%)
            contrast(95%);
          @include mq_min_750 {
            width: 23px;
            height: 23px;
            margin-right: 20px;
          }
          img {
            object-fit: contain;
          }
        }
      }
      .form-item-input {
        padding: convertToVw(15px) convertToVw(15px);
        border: 1px solid #747e91;
        border-radius: 4px;
        // @include set_font(convertToVw(25px), 500, 1);
        @include mq_min_750 {
          padding: 15px 15px;
          //   @include set_font(25px, 500, 1);
        }
      }
      .error-message {
        display: block;
        margin-top: convertToVw(10px);
        color: #d10707;
        @include set_font(convertToVw(20px), 500, 1);
        @include mq_min_750 {
          margin-top: 10px;
          @include set_font(20px, 500, 1);
        }
      }
    }
    .form-button {
      width: 100%;
      margin-top: convertToVw(30px);
      //   @include set_font(convertToVw(25px), 500, 1);
      @include mq_min_750 {
        margin-top: 30px;
        // @include set_font(25px, 500, 1);
      }
    }
  }
}
