// Health knowledge category
.wrapper.health-knowledge {
  .health-knowledge {
    // padding-top: convertToVw(145px);
    // @include mq_min_750 {
    //   padding-top: 145px;
    // }
    .health-knowledge-list {
      .health-knowledge-item {
        @include flex(space-between, center);
        padding: convertToVw(15px) 0;
        border-bottom: 1px solid #9fa5ad;
        // @include set_font(convertToVw(35px), 400, 1);
        transition: all 0.3s ease;
        @include mq_min_750 {
          padding: 15px 0;
          //   @include set_font(30px, 400, 1);
        }
        &:hover {
          color: #1a75bb;
        }
        .health-knowledge-item-icon {
          width: convertToVw(25px);
          height: convertToVw(25px);
          @include mq_min_750 {
            width: 25px;
            height: 25px;
          }
          img {
            object-fit: contain;
          }
        }
      }

      .help-item {
        padding: 15px 0;
        @include set_font(convertToVw(25px), 400, 1);
        @include mq_min_750 {
          padding: 30px 0;
          @include set_font(30px, 400, 1);
        }
      }
    }

    .help-list {
      .help-item {
        display: flex;
        align-items: center;
        padding: 15px 0;
        // @include set_font(convertToVw(25px), 400, 1);
        @include mq_min_750 {
          padding: 30px 0;
          //   @include set_font(30px, 400, 1);
        }
        .help-icon {
          width: convertToVw(35px);
          height: convertToVw(35px);
          @include mq_min_750 {
            width: 35px;
            height: 35px;
          }
          img {
            object-fit: contain;
          }
        }
        .help-text {
          color: blue;
          margin-left: 12px;
        }
      }
    }
  }
}
